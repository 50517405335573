export default {
  'view.threads.newPost.title': 'Nowy post',

  'view.threads.newPost.contentLabel': 'Content ({min}-{max} characters)',
  'view.threads.newPost.previewLabel': 'Preview',

  'view.threads.newPost.previewButton': 'Preview',
  'view.threads.newPost.addPostButton': 'Add Post',

  'view.threads.newPost.addPostToast': 'You have successfully created a new post.'
}
