export default {
  'view.calculators.playerStatsCalculator.title': 'Kalkulator statystyk postaci',

  'view.calculators.playerStatsCalculator.levelLabel': 'Poziom ({min}-{max})',
  'view.calculators.playerStatsCalculator.vocationLabel': 'Profesja',

  'view.calculators.playerStatsCalculator.calculateButton': 'Oblicz',

  'view.calculators.playerStatsCalculator.hitpointsText': 'Życie',
  'view.calculators.playerStatsCalculator.manaText': 'Mana',
  'view.calculators.playerStatsCalculator.capacityText': 'Cap',
  'view.calculators.playerStatsCalculator.speedText': 'Szybkość'
}
