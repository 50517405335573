export default {
  'view.rookstat.deaths.title': 'Śmierci',

  'view.rookstat.deaths.nameLabel': 'Nazwa',

  'view.rookstat.deaths.worldLabel': 'Świat',
  'view.rookstat.deaths.worldAllOption': 'Dowolny',

  'view.rookstat.deaths.sortLabel': 'Sortowanie',
  'view.rookstat.deaths.sortDiedAtAscOption': 'Data śmierci ― Rosnąco',
  'view.rookstat.deaths.sortDiedAtDescOption': 'Data śmierci ― Malejąco',

  'view.rookstat.deaths.nameText': 'Nazwa',
  'view.rookstat.deaths.worldText': 'Świat',
  'view.rookstat.deaths.reasonText': 'Powód',
  'view.rookstat.deaths.dateText': 'Data',

  'view.rookstat.deaths.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
