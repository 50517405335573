export default {
  'view.account.register.title': 'Zarejestruj',

  'view.account.register.usernameLabel': 'Nazwa ({min}-{max} znaków)',
  'view.account.register.emailLabel': 'Email',
  'view.account.register.passwordLabel': 'Hasło',
  'view.account.register.repeatPasswordLabel': 'Powtórz hasło',
  'view.account.register.securityPasswordLabel': 'Jak nazywa się wyspa dla początkujących?',
  'view.account.register.agreementBeforeRulesLabel': 'Akceptuję',
  'view.account.register.agreementAfterRulesBeforePrivacyPolicyLabel': 'i zgadzam się z',

  'view.account.register.repeatPasswordHelp': 'Hasła nie są takie same.',

  'view.account.register.registerButton': 'Zarejestruj',

  'view.account.register.registerToast': 'Twoje konto zostało utworzone, a email z linkiem aktywacyjnym został wysłany.'
}
