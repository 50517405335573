export default {
  'view.rookstat.advancements.title': 'Awanse',

  'view.rookstat.advancements.nameLabel': 'Nazwa',
  'view.rookstat.advancements.worldLabel': 'Świat',
  'view.rookstat.advancements.worldAllOption': 'Dowolny',
  'view.rookstat.advancements.typeLabel': 'Typ',
  'view.rookstat.advancements.sortLabel': 'Sortowanie',
  'view.rookstat.advancements.sortCreatedAtAscOption': 'Utworzono ― Rosnąco',
  'view.rookstat.advancements.sortCreatedAtDescOption': 'Utworzono ― Malejąco',
  'view.rookstat.advancements.sortValueAscOption': 'Wartość ― Rosnąco',
  'view.rookstat.advancements.sortValueDescOption': 'Wartość ― Malejąco',

  'view.rookstat.advancements.nameText': 'Nazwa',
  'view.rookstat.advancements.advancementText': 'Awans',
  'view.rookstat.advancements.dateText': 'Data',

  'view.rookstat.advancements.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
