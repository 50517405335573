export default {
  'view.blog.edit.title': 'Edit Blog',

  'view.blog.edit.titleLabel': 'Title',
  'view.blog.edit.tagLabel': 'Tag',

  'view.blog.edit.tagArticleOption': 'Article',
  'view.blog.edit.tagExclusiveOption': 'Exclusive',
  'view.blog.edit.tagFansiteDevOption': 'Fansite Dev',
  'view.blog.edit.tagFeaturedTheRookstayerOption': 'The Rookstayer',
  'view.blog.edit.tagFeaturedAdvancementsOption': 'Advancements',
  'view.blog.edit.tagInterviewOption': 'Interview',
  'view.blog.edit.tagNewsOption': 'News',
  'view.blog.edit.tagTutorialOption': 'Tutorial',

  'view.blog.edit.languageLabel': 'Language',

  'view.blog.edit.featuredLabel': 'Featured',

  'view.blog.edit.featuredYesOption': 'Yes',
  'view.blog.edit.featuredNoOption': 'No',

  'view.blog.edit.contentLabel': 'Content',

  'view.blog.edit.previewButton': 'Preview',
  'view.blog.edit.editBlogButton': 'Edit Blog',

  'view.blog.edit.updateBlogToast': 'You have successfully updated a blog.'
}
