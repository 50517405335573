import objectPath from 'object-path'

const getItem = path => {
  const item = localStorage.getItem(`state.${path}`)
  if (item) {
    const object = JSON.parse(item)

    return object.value ?? null
  }

  return null
}

const setItem = (path, state) => {
  const object = { value: objectPath.get(state, path) }

  localStorage.setItem(`state.${path}`, JSON.stringify(object))
}

export default paths => store => {
  if (typeof window === 'undefined') {
    return
  }

  paths.forEach(path => {
    objectPath.set(store.state, path, getItem(path))
  })

  store.subscribe((_, state) => {
    paths.forEach(path => {
      setItem(path, state)
    })
  })
}
