export default {
  'view.account.changePassword.title': 'Zmień hasło',

  'view.account.changePassword.newPasswordLabel': 'Hasło (nowe)',
  'view.account.changePassword.confirmPasswordLabel': 'Hasło (aktualne)',

  'view.account.changePassword.changeButton': 'Zmień hasło',

  'view.account.changePassword.changeToast': 'Twoje hasło zostało zmienione.'
}
