export default {
  'view.account.index.title': 'Konto',

  'view.account.index.featuredStatsRankText': 'Ranga',
  'view.account.index.featuredStatsCreatedAtText': 'Dołączył',

  'view.account.index.statsRibbon': 'Statystyki',
  'view.account.index.statsUsernameText': 'Nazwa',
  'view.account.index.statsEmailText': 'Email',
  'view.account.index.statsWarningLevelText': 'Ostrzeżenia',

  'view.account.index.logoutButton': 'Wyloguj się',
  'view.account.index.logoutToast': 'Zostałeś pomyślnie wylogowany.'
}
