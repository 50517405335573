export default {
  'view.threads.editPost.title': 'Edytuj post',

  'view.threads.editPost.contentLabel': 'Treść ({min}-{max} znaków)',
  'view.threads.editPost.previewLabel': 'Podgląd',

  'view.threads.editPost.previewButton': 'Podgląd',
  'view.threads.editPost.editPostButton': 'Edytuj post',

  'view.threads.editPost.editPostToast': 'Pomyślnie edytowałeś post.'
}
