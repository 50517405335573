export default {
  'view.users.index.title': 'Users',

  'view.users.index.usernameLabel': 'Username',

  'view.users.index.roleLabel': 'Role',
  'view.users.index.roleAllOption': 'All',
  'view.users.index.roleUserOption': 'User',
  'view.users.index.roleBloggerOption': 'Blogger',
  'view.users.index.roleEventManagerOption': 'Event Manager',
  'view.users.index.roleModeratorOption': 'Moderator',
  'view.users.index.roleAdminOption': 'Admin',

  'view.users.index.sortLabel': 'Sort',
  'view.users.index.sortCreatedAtAscOption': 'Created At ― Ascending',
  'view.users.index.sortCreatedAtDescOption': 'Created At ― Descending',
  'view.users.index.sortUsernameAscOption': 'Username ― Ascending',
  'view.users.index.sortUsernameDescOption': 'Username ― Descending',
  'view.users.index.sortCommentsAscOption': 'Comments ― Ascending',
  'view.users.index.sortCommentsDescOption': 'Comments ― Descending',
  'view.users.index.sortPostsAscOption': 'Posts ― Ascending',
  'view.users.index.sortPostsDescOption': 'Posts ― Descending',
  'view.users.index.sortThreadsAscOption': 'Threads ― Ascending',
  'view.users.index.sortThreadsDescOption': 'Threads ― Descending',
  'view.users.index.sortImagesAscOption': 'Images ― Ascending',
  'view.users.index.sortImagesDescOption': 'Images ― Descending',
  'view.users.index.sortActivityPointsAscOption': 'Activity Points ― Ascending',
  'view.users.index.sortActivityPointsDescOption': 'Activity Points ― Descending',

  'view.users.index.usernameText': 'Username',
  'view.users.index.commentsText': 'Comments',
  'view.users.index.postsText': 'Posts',
  'view.users.index.threadsText': 'Threads',
  'view.users.index.imagesText': 'Images',
  'view.users.index.activityPointsText': 'Activity Pts',
  'view.users.index.createdAtText': 'Created At',

  'view.users.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
