export default {
  'view.users.user.featuredStatsRankText': 'Rank',
  'view.users.user.featuredStatsActivityPointsText': 'Activity Pts',
  'view.users.user.featuredStatsCreatedAtText': 'Created At',

  'view.users.user.statsTab': 'Stats',

  'view.users.user.statsBlogsText': 'Blogs',
  'view.users.user.statsCommentsText': 'Comments',
  'view.users.user.statsThreadsText': 'Threads',
  'view.users.user.statsPostsText': 'Posts',
  'view.users.user.statsImagesText': 'Images',
  'view.users.user.statsWarningLevelText': 'Warning Level',

  'view.users.user.characterTab': 'Character',

  'view.users.user.characterNameText': 'Name',
  'view.users.user.characterWorldText': 'World',
  'view.users.user.characterLevelText': 'Level',
  'view.users.user.characterAchievementPointsText': 'Achievement Points',

  'view.users.user.buttonsPostsText': 'Posts',
  'view.users.user.buttonsImagesText': 'Images',
  'view.users.user.buttonsWarnText': 'Warn'
}
