export default {
  'view.rookstat.players.player.characterTab': 'Postać',
  'view.rookstat.players.player.characterNameText': 'Nazwa',
  'view.rookstat.players.player.characterLevelText': 'Poziom',
  'view.rookstat.players.player.characterExperienceText': 'Doświadczenie',
  'view.rookstat.players.player.characterAchievementsText': 'Osiągnięcia',
  'view.rookstat.players.player.characterSpeedText': 'Szybkość',
  'view.rookstat.players.player.characterCapacityText': 'Ładowność',
  'view.rookstat.players.player.characterWorldText': 'Świat',
  'view.rookstat.players.player.characterSexText': 'Płeć',
  'view.rookstat.players.player.characterAccountStatusText': 'Status konta',
  'view.rookstat.players.player.characterLastLoginText': 'Ostatnie zalogowanie',
  'view.rookstat.players.player.characterLastUpdateText': 'Ostatnia aktualizacja',

  'view.rookstat.players.player.skillsTab': 'Umiejętności',
  'view.rookstat.players.player.skillsNaText': '―',

  'view.rookstat.players.player.predictionsTab': 'Prognozy',
  'view.rookstat.players.player.predictionsNextLevelAtText': 'Następny poziom',
  'view.rookstat.players.player.predictionsNextLevelAtDescriptionText': 'Ta prognoza bazuje na dwóch ostatnich awansach.',

  'view.rookstat.players.player.onlineTimesTab': 'Czas online',
  'view.rookstat.players.player.onlineTimesPreviousMonthText': 'Poprzedni miesiąc',
  'view.rookstat.players.player.onlineTimesThisMonthText': 'Ten miesiąc',
  'view.rookstat.players.player.onlineTimesThisWeekText': 'Ten tydzień',
  'view.rookstat.players.player.onlineTimesSixDaysAgoText': '6 dni temu',
  'view.rookstat.players.player.onlineTimesFiveDaysAgoText': '5 dni temu',
  'view.rookstat.players.player.onlineTimesFourDaysAgoText': '4 dni temu',
  'view.rookstat.players.player.onlineTimesThreeDaysAgoText': '3 dni temu',
  'view.rookstat.players.player.onlineTimesTwoDaysAgoText': '2 dni temu',
  'view.rookstat.players.player.onlineTimesYesterdayText': 'Wczoraj',
  'view.rookstat.players.player.onlineTimesTodayText': 'Dzisiaj',

  'view.rookstat.players.player.avancementsTab': 'Awanse',
  'view.rookstat.players.player.avancementsGoToAdvancementsText': 'Idź do awansów',

  'view.rookstat.players.player.deathsTab': 'Śmierci',
  'view.rookstat.players.player.deathsGoToDeathsText': 'Idź do śmierci',

  'view.rookstat.players.player.formerNamesTab': 'Poprzednie nazwy',
  'view.rookstat.players.player.formerNamesNoFormerNamesText': 'Ta postać nie ma poprzednich nazw.',

  'view.rookstat.players.player.sourceText': 'Źródło'
}
