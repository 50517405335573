export default {
  component: {
    score: {
      scoreZeroHelp: 'Wynik jest bardzo słaby.',
      scoreTooLowHelp: 'Wynik nadal jest za słaby.',
      scoreOkHelp: 'Wynik jest wystarczająco dobry!',
      scoreMaxHelp: 'Świetnie! Wynik jest bardzo dobry.'
    }
  }
}
