export default {
  'view.blog.blog.commentsText': 'Komentarze',
  'view.blog.blog.languageText': 'Język',
  'view.blog.blog.viewsText': 'Odsłony',
  'view.blog.blog.lengthMinText': 'Długość [min]',
  'view.blog.blog.commentsCountText': 'Komentarze ({count})',
  'view.blog.blog.noCommentsText': 'Aktualnie nie ma żadnych komentarzy.',
  'view.blog.blog.commentLabel': 'Komentarz ({min}-{max} znaków)',
  'view.blog.blog.addCommentButton': 'Dodaj komentarz',

  'view.blog.blog.deleteBlogConfirm': 'Jesteś pewien, że chcesz usunąć tego bloga?',
  'view.blog.blog.deleteBlogToast': 'Udało ci się usunąć bloga.',

  'view.blog.blog.commentDeletedText': 'Ten komentarz został usunięty przez moderatora lub administratora.',

  'view.blog.blog.createCommentToast': 'Udało ci się dodać komentarz.',

  'view.blog.blog.updateCommentToast': 'Udało ci się zaktualizować komentarz.',

  'view.blog.blog.deleteCommentConfirm': 'Jesteś pewien, że chcesz usunąć ten komentarz?',
  'view.blog.blog.deleteCommentToast': 'Udało ci się usunąć komentarz.'
}
