export default {
  'view.library.items.index.title': 'Przedmioty',

  'view.library.items.index.nameLabel': 'Nazwa',

  'view.library.items.index.sortLabel': 'Sortowanie',
  'view.library.items.index.sortNameAscOption': 'Nazwa ― Rosnąco',
  'view.library.items.index.sortNameDescOption': 'Nazwa ― Malejąco',

  'view.library.items.index.sortAscText': 'Rosnąco',
  'view.library.items.index.sortDescText': 'Malejąco',

  'view.library.items.index.nameText': 'Nazwa',
  'view.library.items.index.weightText': 'Waga (oz.)',
  'view.library.items.index.volumeText': 'Pojemność',
  'view.library.items.index.protectionEarthText': 'Ochrona (ziemia)',
  'view.library.items.index.protectionLifeDrainText': 'Ochrona (Wyssanie życia)',
  'view.library.items.index.protectionManaDrainText': 'Ochrona (Wyssanie many)',
  'view.library.items.index.durationText': 'Czas trwania (sekundy)',
  'view.library.items.index.armorText': 'Pancerz',
  'view.library.items.index.attackText': 'Atak',
  'view.library.items.index.defenseText': 'Obrona',
  'view.library.items.index.defenseModifierText': 'Modyfikator obrony',
  'view.library.items.index.rangeText': 'Zasięg',

  'view.library.items.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
