export default {
  'view.library.achievements.index.title': 'Osiągnięcia',

  'view.library.achievements.index.searchLabel': 'Szukaj',

  'view.library.achievements.index.gradeLabel': 'Stopień',
  'view.library.achievements.index.gradeAllOption': 'Dowolny',

  'view.library.achievements.index.secretLabel': 'Sekret',
  'view.library.achievements.index.secretAllOption': 'Dowolny',
  'view.library.achievements.index.secretYesOption': 'Tak',
  'view.library.achievements.index.secretNoOption': 'Nie',

  'view.library.achievements.index.sortLabel': 'Sortowanie',
  'view.library.achievements.index.sortNameAscOption': 'Nazwa ― Rosnąco',
  'view.library.achievements.index.sortNameDescOption': 'Nazwa ― Malejąco',
  'view.library.achievements.index.sortPointsAscOption': 'Punkty ― Rosnąco',
  'view.library.achievements.index.sortPointsDescOption': 'Punkty ― Malejąco',

  'view.library.achievements.index.nameText': 'Nazwa',
  'view.library.achievements.index.gradeText': 'Stopień {grade}',
  'view.library.achievements.index.pointsText': 'Punkty',

  'view.library.achievements.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
