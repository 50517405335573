export default {
  'view.account.resetPassword.title': 'Zresetuj hasło',

  'view.account.resetPassword.usernameLabel': 'Nazwa',
  'view.account.resetPassword.emailLabel': 'Email',

  'view.account.resetPassword.sendPassworResetLinkButton': 'Wyślij link resetujący hasło',

  'view.account.resetPassword.resetToast': 'Link resetujący hasło został wysłany.'
}
