export default {
  'view.images.edit.title': 'Edit Image',

  'view.images.edit.fileLabel': 'File',

  'view.images.edit.tagLabel': 'Tag',
  'view.images.edit.tagAdvancementOption': 'Advancement',
  'view.images.edit.tagBossOption': 'Boss',
  'view.images.edit.tagCollectionOption': 'Collection',
  'view.images.edit.tagEventOption': 'Event',
  'view.images.edit.tagFansiteEventOption': 'Fansite Event',
  'view.images.edit.tagFriendsOption': 'Friends',
  'view.images.edit.tagFunnyOption': 'Funny',
  'view.images.edit.tagLootOption': 'Loot',
  'view.images.edit.tagOldTibiaOption': 'Old Tibia',
  'view.images.edit.tagTrapandoOption': 'Trapando',
  'view.images.edit.tagBlogRookieTeamOption': 'Blog (Rookie Team)',
  'view.images.edit.tagOtherRookieTeamOption': 'Other (Rookie Team)',

  'view.images.edit.editButton': 'Edit',

  'view.images.image.editImageToast': 'You have successfully edited an image.'
}
