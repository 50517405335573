export default {
  state: {
    language: null
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language
    }
  }
}
