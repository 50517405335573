export default {
  'view.threads.edit.title': 'Edit Thread',

  'view.threads.edit.subjectLabel': 'Subject ({min}-{max} characters)',
  'view.threads.edit.contentLabel': 'Content ({min}-{max} characters)',
  'view.threads.edit.previewLabel': 'Preview',

  'view.threads.edit.previewButton': 'Preview',
  'view.threads.edit.editThreadButton': 'Edit Thread',

  'view.threads.edit.editThreadToast': 'You have successfully updated a thread.'
}
