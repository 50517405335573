<template>
  <transition-group name="list">
    <template v-for="({ id, color, message }, i) in toasts" :key="id">
      <div :class="`notification is-marginless is-${color}`" :style="`z-index: ${i + 5}`">
        <button @click="removeToast(id)" class="delete"></button>
        <div class="container">
          <small>{{ parseMessage(message) }}</small>
        </div>
      </div>
    </template>
  </transition-group>
</template>

<style lang="sass" scoped>
.notification
  position: fixed
  bottom: 0
  opacity: 90%
  width: 100vw
  border-radius: 0

.list-move,
.list-enter-active,
.list-leave-active
  transition: transform .5s ease-out, opacity .5s ease-out

.list-enter-from,
.list-leave-to
  opacity: 0
  transform: translateY(100%)
</style>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'app-toaster',
  computed: {
    ...mapState({
      toasts: ({ toast }) => {
        return toast.toasts.reverse()
      }
    })
  },
  methods: {
    ...mapMutations({
      removeToast: 'REMOVE_TOAST'
    }),
    parseMessage (message) {
      return message.startsWith('$t:') ? this.$t(message.replace('$t:', '')) : message
    }
  }
}
</script>
