export default {
  'view.rookstat.bosses.title': 'Bossy',

  'view.rookstat.bosses.nameLabel': 'Nazwa',
  'view.rookstat.bosses.worldLabel': 'Świat',
  'view.rookstat.bosses.sortLabel': 'Sortowanie',
  'view.rookstat.bosses.sortDateAscOption': 'Data ― Rosnąco',
  'view.rookstat.bosses.sortDateDescOption': 'Data ― Malejąco',

  'view.rookstat.bosses.resultText': 'Wynik',
  'view.rookstat.bosses.dateText': 'Data',

  'view.rookstat.bosses.wasntKilledText': 'nie został zabity na świecie',
  'view.rookstat.bosses.wasKilledText': 'został zabity',
  'view.rookstat.bosses.timesOnText': '- | raz na świecie | razy na świecie',
  'view.rookstat.bosses.thatDayText': 'w tym dniu',

  'view.rookstat.bosses.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
