export default {
  component: {
    pager: {
      beforeEdgesText: 'Showing',
      betweenEdgesText: 'to',
      afterEdgesText: 'of',
      resultsText: 'result | results'
    }
  }
}
