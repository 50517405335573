export default {
  state: {
    id: undefined,
    username: undefined,
    roles: [],
    banned: false,
    token: undefined,
    authenticated: false
  },
  mutations: {
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_USERNAME: (state, username) => {
      state.username = username
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_BANNED: (state, banned) => {
      state.banned = banned
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_AUTHENTICATED: (state, authenticated) => {
      state.authenticated = authenticated
    }
  },
  actions: {
    LOGIN: (context, auth) => {
      const { id, username, roles, banned, token } = auth

      context.commit('SET_ID', id)
      context.commit('SET_USERNAME', username)
      context.commit('SET_ROLES', roles)
      context.commit('SET_BANNED', banned)
      context.commit('SET_TOKEN', token)
      context.commit('SET_AUTHENTICATED', !!token)
    },
    LOGOUT: context => {
      context.commit('SET_ID', undefined)
      context.commit('SET_USERNAME', undefined)
      context.commit('SET_ROLES', [])
      context.commit('SET_BANNED', false)
      context.commit('SET_TOKEN', undefined)
      context.commit('SET_AUTHENTICATED', false)
    }
  }
}
