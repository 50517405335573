export default {
  'view.library.creatures.index.title': 'Creatures',

  'view.library.creatures.index.nameLabel': 'Name',

  'view.library.creatures.index.typeLabel': 'Type',
  'view.library.creatures.index.typeAllOption': 'All Types',
  'view.library.creatures.index.typeRegularOption': 'Regular',
  'view.library.creatures.index.typeBossOption': 'Boss',
  'view.library.creatures.index.typeQuestOption': 'Quest',
  'view.library.creatures.index.typeRemovedOption': 'Removed',
  'view.library.creatures.index.typeSummonOption': 'Summon',

  'view.library.creatures.index.reachabilityLabel': 'Reachability',
  'view.library.creatures.index.reachabilityAllOption': 'All',
  'view.library.creatures.index.reachabilityReachableOption': 'Reachable',
  'view.library.creatures.index.reachabilityUnreachableOption': 'Unreachable',

  'view.library.creatures.index.sortLabel': 'Sort',
  'view.library.creatures.index.sortNameAscOption': 'Name ― Ascending',
  'view.library.creatures.index.sortNameDescOption': 'Name ― Descending',
  'view.library.creatures.index.sortExperienceAscOption': 'Experience ― Ascending',
  'view.library.creatures.index.sortExperienceDescOption': 'Experience ― Descending',
  'view.library.creatures.index.sortHitpointsAscOption': 'Hitpoints ― Ascending',
  'view.library.creatures.index.sortHitpointsDescOption': 'Hitpoints ― Descending',
  'view.library.creatures.index.sortCharmsAscOption': 'Charms ― Ascending',
  'view.library.creatures.index.sortCharmsDescOption': 'Charms ― Descending',
  'view.library.creatures.index.sortSpeedAscOption': 'Speed ― Ascending',
  'view.library.creatures.index.sortSpeedDescOption': 'Speed ― Descending',
  'view.library.creatures.index.sortArmorAscOption': 'Armor ― Ascending',
  'view.library.creatures.index.sortArmorDescOption': 'Armor ― Descending',

  'view.library.creatures.index.nameText': 'Name',
  'view.library.creatures.index.experienceText': 'Experience',
  'view.library.creatures.index.hitpointsText': 'Hitpoints',
  'view.library.creatures.index.charmsText': 'Charms',
  'view.library.creatures.index.speedText': 'Speed',
  'view.library.creatures.index.armorText': 'Armor',

  'view.library.creatures.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
