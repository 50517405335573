export default {
  'view.calculators.staminaCalculator.title': 'Kalkulator wytrzymałości',

  'view.calculators.staminaCalculator.fromLabel': 'Od ({min}-{max})',
  'view.calculators.staminaCalculator.toLabel': 'Do ({min}-{max})',

  'view.calculators.staminaCalculator.calculateButton': 'Oblicz',

  'view.calculators.staminaCalculator.timeText': 'Czas',
  'view.calculators.staminaCalculator.dateText': 'Data'
}
