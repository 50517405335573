export default {
  'view.calculators.huntingTimeCalculator.title': 'Hunting Time Calculator',

  'view.calculators.huntingTimeCalculator.experiencePerHourLabel': 'Experience Per Hour ({min}-{max})',
  'view.calculators.huntingTimeCalculator.fromLabel': 'From ({min}-{max})',
  'view.calculators.huntingTimeCalculator.fromPercentToNextLabel': '% To Next',
  'view.calculators.huntingTimeCalculator.toLabel': 'To ({min}-{max})',
  'view.calculators.huntingTimeCalculator.toPercentToNextLabel': '% To Next',

  'view.calculators.huntingTimeCalculator.calculateButton': 'Calculate',

  'view.calculators.huntingTimeCalculator.timeText': 'Time',
  'view.calculators.huntingTimeCalculator.dateText': 'Date'
}
