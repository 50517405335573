<template>
  <span class="icon is-large">
    <fa-icon :icon="['fas', 'spinner']" size="2x" spin/>
  </span>
</template>

<script>
export default {
  name: 'v-spinner'
}
</script>
