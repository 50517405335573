<template>
  <v-toaster/>
  <v-navbar/>
  <div class="section">
    <div class="block">
      <div class="container is-narrow">
        <div class="columns is-mobile is-vcentered">
          <div class="column is-size-7">
          <em>{{ $t('app.locale.youAreBrowsingFansiteInText') }} <strong>{{ $t(`app.locale.${locale}Text`) }}</strong>.</em>
          </div>
          <div class="column is-narrow">
            <div class="is-flex">
              <a role="button" @click="setLocaleLanguage('pl')" class="mr-1">
                <figure class="image is-16x16 has-border-radius-rounded is-loadable">
                  <v-image image="flags/Polish.svg" alt="Polish"/>
                </figure>
              </a>
              <a role="button" @click="setLocaleLanguage('en')">
                <figure class="image is-16x16 has-border-radius-rounded is-loadable">
                  <v-image image="flags/English.svg" alt="English"/>
                </figure>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container is-narrow">
      <v-state :data="charactersOnline && boostedCreature && latestFansiteContest">
        <template #resolved>
          <div class="columns is-centered">
            <div class="column">
              <div class="is-flex is-align-items-center is-justify-content-center">
                <div>
                  <a @click="slide = (((slide - 1) % sliderElements.length) + sliderElements.length) % sliderElements.length; slideDirection = 'left'" class="icon" role="button">
                    <fa-icon :icon="['fas', 'chevron-left']"/>
                  </a>
                </div>
                <div class="has-text-centered is-flex-grow-1 mx-5">
                  <template v-for="({ translationKey, to, value }, index) of sliderElements" :key="index">
                    <template v-if="slide === index">
                      <transition :name="transition" mode="in-out" appear>
                        <div>
                          <div class="tags is-centered mb-0">
                            <span class="tag is-primary">{{ $t(translationKey) }}</span>
                          </div>
                          <div class="has-white-space-nowrap">
                            <router-link :to="to">
                              <strong>{{ value }}</strong>
                            </router-link>
                          </div>
                        </div>
                      </transition>
                    </template>
                  </template>
                </div>
                <div>
                  <a @click="slide = (slide + 1) % sliderElements.length; slideDirection = 'right'" class="icon" role="button">
                    <fa-icon :icon="['fas', 'chevron-right']"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #unresolved>
          <div class="block has-text-centered" style="margin: 1.05rem;">
            <v-spinner/>
          </div>
        </template>
      </v-state>
    </div>
  </div>
  <v-breadcrumbs/>
  <div class="section">
    <div class="block">
      <router-view/>
    </div>
    <div class="container">
      <div class="has-text-centered has-text-grey-lighter">
        <small>
          <em v-html="$t('app.usersText', { viewers, users }) + $t('app.usersLoggedInText', { count: users })"/>
        </small>
      </div>
    </div>
  </div>
  <v-footer/>
</template>

<style lang="sass">
@import @/styles

=slide-fade($direction)
  &-enter-active
    transition: all 0.3s ease-out

  &-leave-active
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1)

  &-enter-from,
  &-leave-to
    @if $direction == left
      transform: translateX(-75px)
    @else if $direction == right
      transform: translateX(75px)
    opacity: 0

.slide-fade-left
  +slide-fade(left)

.slide-fade-right
  +slide-fade(right)

</style>

<script lang="ts">
import { defineComponent } from 'vue'

import State from '@/components/State.vue'
import Spinner from '@/components/Spinner.vue'

import Toaster from '@/components/Toaster.vue'
import Navbar from '@/components/Navbar.vue'
import Image from '@/components/Image.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Footer from '@/components/Footer.vue'

import i18n from '@/i18n'

import { auth } from '@/lib/auth'
import { getLanguage, setLanguage } from '@/lib/language'
import { getLocale, setLocale } from '@/lib/locale'
import { isBrowser } from '@/lib/platform'
import { getTitle } from '@/lib/route'
import { toastDanger } from '@/lib/toast'

export default defineComponent({
  components: {
    'v-state': State,
    'v-spinner': Spinner,
    'v-toaster': Toaster,
    'v-navbar': Navbar,
    'v-image': Image,
    'v-breadcrumbs': Breadcrumbs,
    'v-footer': Footer
  },
  watch: {
    $route (): void {
      this.updateTitle()
      this.updateGoogleAnalytics()
      this.updateLastActiveAt()
    },
    title (): void {
      this.updateTitle()
    },
    locale (): void {
      i18n.global.locale = this.locale
    }
  },
  computed: {
    locale (): string {
      return getLocale()
    },
    title (): string|undefined {
      return getTitle()
    },
    transition (): string {
      return this.slideDirection === 'left' ? 'slide-fade-left' : 'slide-fade-right'
    },
    sliderElements (): { translationKey: string, to: any, value?: string | number }[] {
      return [
        {
          translationKey: 'app.charactersOnline',
          to: { name: 'rookstat.players.index', query: { online: 'online' } },
          value: this.charactersOnline
        },
        /* {
          translationKey: 'Event',
          value: 'Rapid Respawn Weekend'
        }, */
        {
          translationKey: 'app.boostedCreature',
          to: { name: 'rookstat.boostedCreatures.index' },
          value: this.boostedCreature
        },
        {
          translationKey: 'app.latestFansiteContest',
          to: { name: 'threads.thread.index', params: { id: this.latestFansiteContest?.id, slug: this.$slugify(this.latestFansiteContest?.subject ?? '') } },
          value: this.latestFansiteContest?.subject
        }
      ]
    }
  },
  methods: {
    async setLocaleLanguage (locale: 'en' | 'pl'): Promise<void> {
      await Promise.all([
        setLocale(locale),
        setLanguage(locale)
      ])
    },
    updateGoogleAnalytics (): void {
      if (isBrowser()) {
        const { name, fullPath, path } = this.$route
        const { href } = new URL(fullPath, window.location.origin)

        this.$gtag.pageview({
          page_title: name?.toString(),
          page_path: path,
          page_location: href
        })
      }
    },
    updateLastActiveAt (): void {
      this.$axios.post('/viewers').catch(({ response: { data: { errors } } }): void => {
        for (const { message } of (errors ?? [])) {
          toastDanger(message)
        }
      })

      if (!auth.authenticated) {
        return
      }

      this.$axios.post('/account/online').catch(({ response: { data: { errors } } }): void => {
        for (const { message } of (errors ?? [])) {
          toastDanger(message)
        }
      })
    },
    updateHtmlLang (): void {
      document.documentElement.setAttribute('lang', getLanguage())
    },
    updateTitle (): void {
      const parsedTitle = this.title?.startsWith('$t:') ? this.$t(this.title.replace('$t:', '')) : this.title

      document.title = parsedTitle ? `${parsedTitle} - Rookie.com.pl` : 'Rookie.com.pl'
    }
  },
  mounted (): void {
    this.updateHtmlLang()
    this.updateTitle()
    this.updateGoogleAnalytics()
    this.updateLastActiveAt()

    this.slideInterval = setInterval((): void => {
      this.slide = (this.slide + 1) % this.sliderElements.length
    }, 5 * 1000)
  },
  unmounted (): void {
    clearInterval(this.slideInterval)
  },
  created () {
    const params = {
      limit: 1,
      sort: 'id',
      order: 'desc'
    }

    this.$axios.get('/boosted-creatures', { params }).then(({ data: { data: { items } } }): void => {
      if (items.at(0)) {
        const [{ name }] = items

        this.boostedCreature = name
      }
    }).catch(({ response: { data: { errors } } }): void => {
      for (const { message } of (errors ?? [])) {
        toastDanger(message)
      }
    })

    this.$axios.get('/characters/online').then(({ data: { data: { items } } }): void => {
      this.charactersOnline = items
    }).catch(({ response: { data: { errors } } }): void => {
      for (const { message } of (errors ?? [])) {
        toastDanger(message)
      }
    })

    this.$axios.get('/threads', { params: { criteria: { forum: 2 }, limit: 1, sort: 'createdAt', order: 'desc' } }).then(({ data: { data: { items: [{ id, subject }] } } }): void => {
      this.latestFansiteContest = { id, subject }
    }).catch(({ response: { data: { errors } } }): void => {
      for (const { message } of (errors ?? [])) {
        toastDanger(message)
      }
    })

    this.$axios.get('/viewers').then(({ data: { data: { totalCount } } }): void => {
      this.viewers = totalCount
    }).catch(({ response: { data: { errors } } }): void => {
      for (const { message } of (errors ?? [])) {
        toastDanger(message)
      }
    })

    this.$axios.get('/users', { params: { criteria: { active: 1 } } }).then(({ data: { data: { totalCount } } }): void => {
      this.users = totalCount
    }).catch(({ response: { data: { errors } } }): void => {
      for (const { message } of (errors ?? [])) {
        toastDanger(message)
      }
    })
  },
  data () {
    return {
      slide: 1,
      slideDirection: 'right',
      slideInterval: undefined as number|undefined,
      boostedCreature: undefined,
      charactersOnline: undefined,
      latestFansiteContest: undefined as { id: number, subject: string }|undefined,
      viewers: 0,
      users: 0
    }
  }
})
</script>
