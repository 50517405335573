export default {
  'view.library.achievements.index.title': 'Achievements',

  'view.library.achievements.index.searchLabel': 'Search',

  'view.library.achievements.index.gradeLabel': 'Grade',
  'view.library.achievements.index.gradeAllOption': 'All',

  'view.library.achievements.index.secretLabel': 'Secret',
  'view.library.achievements.index.secretAllOption': 'All',
  'view.library.achievements.index.secretYesOption': 'Yes',
  'view.library.achievements.index.secretNoOption': 'No',

  'view.library.achievements.index.sortLabel': 'Sort',
  'view.library.achievements.index.sortNameAscOption': 'Name ― Ascending',
  'view.library.achievements.index.sortNameDescOption': 'Name ― Descending',
  'view.library.achievements.index.sortPointsAscOption': 'Points ― Ascending',
  'view.library.achievements.index.sortPointsDescOption': 'Points ― Descending',

  'view.library.achievements.index.nameText': 'Name',
  'view.library.achievements.index.gradeText': 'Grade {grade}',
  'view.library.achievements.index.pointsText': 'Points',

  'view.library.achievements.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
