export default {
  'view.library.npcs.index.title': 'NPC',

  'view.library.npcs.index.nameLabel': 'Nazwa',

  'view.library.npcs.index.sortLabel': 'Sortowanie',
  'view.library.npcs.index.sortNameAscOption': 'Nazwa ― Rosnąco',
  'view.library.npcs.index.sortNameDescOption': 'Nazwa ― Malejąco',

  'view.library.npcs.index.nameText': 'Nazwa',
  'view.library.npcs.index.jobText': 'Profesja',
  'view.library.npcs.index.locationText': 'Lokacja',

  'view.library.npcs.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
