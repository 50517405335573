export default {
  'view.images.image.uploadedByText': 'Dodano przez',
  'view.images.image.uploadedAtText': 'Dodano o',

  'view.images.image.sourceLabel': 'Źródło',
  'view.images.image.bbcodeLabel': 'BBCode',

  'view.images.image.deleteButton': 'Usuń obrazek',

  'view.images.image.deleteImageConfirm': 'Jesteś pewien, że chcesz usunąć ten obrazek?',
  'view.images.image.deleteImageToast': 'Pomyślnie usunąłeś obrazek.'
}
