export default {
  'view.rookstat.bosses.title': 'Bosses',

  'view.rookstat.bosses.nameLabel': 'Name',
  'view.rookstat.bosses.worldLabel': 'World',
  'view.rookstat.bosses.sortLabel': 'Sort',
  'view.rookstat.bosses.sortDateAscOption': 'Created At ― Ascending',
  'view.rookstat.bosses.sortDateDescOption': 'Created At ― Descending',

  'view.rookstat.bosses.resultText': 'Result',
  'view.rookstat.bosses.dateText': 'Date',

  'view.rookstat.bosses.wasntKilledText': 'wasn\'t killed on',
  'view.rookstat.bosses.wasKilledText': 'was killed',
  'view.rookstat.bosses.timesOnText': 'time on | time(s) on',
  'view.rookstat.bosses.thatDayText': 'that day',

  'view.rookstat.bosses.emptyListText': 'Sorry, but no results match your search criteria.'
}
