export default {
  'view.calculators.staminaCalculator.title': 'Stamina Calculator',

  'view.calculators.staminaCalculator.fromLabel': 'From ({min}-{max})',
  'view.calculators.staminaCalculator.toLabel': 'To ({min}-{max})',

  'view.calculators.staminaCalculator.calculateButton': 'Calculate',

  'view.calculators.staminaCalculator.timeText': 'Time',
  'view.calculators.staminaCalculator.dateText': 'Date'
}
