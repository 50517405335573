export default {
  'view.calculators.huntingTimeCalculator.title': 'Kalkulator czasu polowania',

  'view.calculators.huntingTimeCalculator.experiencePerHourLabel': 'Doświadczenie na godzinę ({min}-{max})',
  'view.calculators.huntingTimeCalculator.fromLabel': 'Od ({min}-{max})',
  'view.calculators.huntingTimeCalculator.fromPercentToNextLabel': '% do nast.',
  'view.calculators.huntingTimeCalculator.toLabel': 'Do ({min}-{max})',
  'view.calculators.huntingTimeCalculator.toPercentToNextLabel': '% do nast.',

  'view.calculators.huntingTimeCalculator.calculateButton': 'Oblicz',

  'view.calculators.huntingTimeCalculator.timeText': 'Czas',
  'view.calculators.huntingTimeCalculator.dateText': 'Data'
}
