export default {
  'view.library.creatures.index.title': 'Potwory',

  'view.library.creatures.index.nameLabel': 'Nazwa',

  'view.library.creatures.index.typeLabel': 'Typ',
  'view.library.creatures.index.typeAllOption': 'Wszystkie typy',
  'view.library.creatures.index.typeRegularOption': 'Regularny',
  'view.library.creatures.index.typeBossOption': 'Boss',
  'view.library.creatures.index.typeQuestOption': 'Questowy',
  'view.library.creatures.index.typeRemovedOption': 'Wycofany',
  'view.library.creatures.index.typeSummonOption': 'Przywołany',

  'view.library.creatures.index.reachabilityLabel': 'Osiągalność',
  'view.library.creatures.index.reachabilityAllOption': 'Dowolna',
  'view.library.creatures.index.reachabilityReachableOption': 'Osiągalny',
  'view.library.creatures.index.reachabilityUnreachableOption': 'Nieosiągalny',

  'view.library.creatures.index.sortLabel': 'Sortowanie',
  'view.library.creatures.index.sortNameAscOption': 'Nazwa ― Rosnąco',
  'view.library.creatures.index.sortNameDescOption': 'Nazwa ― Malejąco',
  'view.library.creatures.index.sortExperienceAscOption': 'Doświadczenie ― Rosnąco',
  'view.library.creatures.index.sortExperienceDescOption': 'Doświadczenie ― Malejąco',
  'view.library.creatures.index.sortHitpointsAscOption': 'Życie ― Rosnąco',
  'view.library.creatures.index.sortHitpointsDescOption': 'Życie ― Malejąco',
  'view.library.creatures.index.sortCharmsAscOption': 'Urok ― Rosnąco',
  'view.library.creatures.index.sortCharmsDescOption': 'Urok ― Malejąco',
  'view.library.creatures.index.sortSpeedAscOption': 'Szybkość ― Rosnąco',
  'view.library.creatures.index.sortSpeedDescOption': 'Szybkość ― Malejąco',
  'view.library.creatures.index.sortArmorAscOption': 'Pancerz ― Rosnąco',
  'view.library.creatures.index.sortArmorDescOption': 'Pancerz ― Malejąco',

  'view.library.creatures.index.nameText': 'Nazwa',
  'view.library.creatures.index.experienceText': 'Doświadczenie',
  'view.library.creatures.index.hitpointsText': 'Życie',
  'view.library.creatures.index.charmsText': 'Urok',
  'view.library.creatures.index.speedText': 'Szybkość',
  'view.library.creatures.index.armorText': 'Pancerz',

  'view.library.creatures.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
