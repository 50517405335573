export default {
  'view.rookstat.boostedCreatures.title': 'Wzmocnione potwory',

  'view.rookstat.boostedCreatures.searchLabel': 'Szukaj',
  'view.rookstat.boostedCreatures.sortLabel': 'Sortowanie',
  'view.rookstat.boostedCreatures.sortDateAscOption': 'Data ― Rosnąco',
  'view.rookstat.boostedCreatures.sortDateDescOption': 'Data ― Malejąco',

  'view.rookstat.boostedCreatures.nameText': 'Nazwa',
  'view.rookstat.boostedCreatures.dateText': 'Data',

  'view.rookstat.boostedCreatures.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
