export default {
  'view.account.anonymize.title': 'Anonimizuj',

  'view.account.anonymize.descriptionText': 'Anonimizacja oznacza, że twoja nazwa użytkownika i adres e-mail zmienią się w nierozpoznawalny alias i twój profil nie będzie już dostępny.',

  'view.account.anonymize.confirmPasswordLabel': 'Hasło',
  'view.account.anonymize.agreementLabel': 'Jestem świadomy, że tej akcji nie można cofnąć.',

  'view.account.anonymize.anonymizeButton': 'Anonimizuj',

  'view.account.anonymize.anonymizeToast': 'Twoje konto zostało zanonimizowane.'
}
