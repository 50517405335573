export default {
  'view.rookstat.worlds.title': 'Światy',

  'view.rookstat.worlds.nameLabel': 'Nazwa',

  'view.rookstat.worlds.locationLabel': 'Lokalizacja',
  'view.rookstat.worlds.locationAllOption': 'Dowolna',
  'view.rookstat.worlds.locationEuropeOption': 'Europa',
  'view.rookstat.worlds.locationNorthAmericaOption': 'Ameryka Północna',
  'view.rookstat.worlds.locationSouthAmericaOption': 'Ameryka Południowa',
  'view.rookstat.worlds.locationOceaniaOption': 'Oceania',

  'view.rookstat.worlds.sortLabel': 'Sortowanie',
  'view.rookstat.worlds.sortNameAscOption': 'Nazwa ― Rosnąco',
  'view.rookstat.worlds.sortNameDescOption': 'Nazwa ― Malejąco',

  'view.rookstat.worlds.nameText': 'Nazwa',
  'view.rookstat.worlds.battleyeText': 'BattlEye',
  'view.rookstat.worlds.playersText': 'Postacie',
  'view.rookstat.worlds.maxLevelText': 'Maks. poziom',
  'view.rookstat.worlds.avgLevelText': 'Śr. poziom',
  'view.rookstat.worlds.pvpTypeText': 'Typ PvP',

  'view.library.worlds.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
