export default {
  'view.rookstat.players.player.characterTab': 'Character',
  'view.rookstat.players.player.characterNameText': 'Name',
  'view.rookstat.players.player.characterLevelText': 'Level',
  'view.rookstat.players.player.characterExperienceText': 'Experience',
  'view.rookstat.players.player.characterAchievementsText': 'Achievements',
  'view.rookstat.players.player.characterSpeedText': 'Speed',
  'view.rookstat.players.player.characterCapacityText': 'Capacity',
  'view.rookstat.players.player.characterWorldText': 'World',
  'view.rookstat.players.player.characterSexText': 'Sex',
  'view.rookstat.players.player.characterAccountStatusText': 'Account Status',
  'view.rookstat.players.player.characterLastLoginText': 'Last Login',
  'view.rookstat.players.player.characterLastUpdateText': 'Last Update',

  'view.rookstat.players.player.skillsTab': 'Skills',
  'view.rookstat.players.player.skillsNaText': 'N/A',

  'view.rookstat.players.player.predictionsTab': 'Predictions',
  'view.rookstat.players.player.predictionsNextLevelAtText': 'Next Level At',
  'view.rookstat.players.player.predictionsNextLevelAtDescriptionText': 'This prediction is based on two most recent level-ups.',

  'view.rookstat.players.player.onlineTimesTab': 'Online Times',
  'view.rookstat.players.player.onlineTimesPreviousMonthText': 'Previous Month',
  'view.rookstat.players.player.onlineTimesThisMonthText': 'This Month',
  'view.rookstat.players.player.onlineTimesThisWeekText': 'This Week',
  'view.rookstat.players.player.onlineTimesSixDaysAgoText': '6 Days Ago',
  'view.rookstat.players.player.onlineTimesFiveDaysAgoText': '5 Days Ago',
  'view.rookstat.players.player.onlineTimesFourDaysAgoText': '4 Days Ago',
  'view.rookstat.players.player.onlineTimesThreeDaysAgoText': '3 Days Ago',
  'view.rookstat.players.player.onlineTimesTwoDaysAgoText': '2 Days Ago',
  'view.rookstat.players.player.onlineTimesYesterdayText': 'Yesterday',
  'view.rookstat.players.player.onlineTimesTodayText': 'Today',

  'view.rookstat.players.player.avancementsTab': 'Advancements',
  'view.rookstat.players.player.avancementsGoToAdvancementsText': 'Go to Advancements',

  'view.rookstat.players.player.deathsTab': 'Deaths',
  'view.rookstat.players.player.deathsGoToDeathsText': 'Go to Deaths',

  'view.rookstat.players.player.formerNamesTab': 'Former Names',
  'view.rookstat.players.player.formerNamesNoFormerNamesText': 'This player has no former name.',
  'view.rookstat.players.player.sourceText': 'Source'
}
