export default {
  'view.account.login.title': 'Login',

  'view.account.login.usernameLabel': 'Username',
  'view.account.login.passwordLabel': 'Password',
  'view.account.login.keepMeLoggedInLabel': 'Keep me logged in.',

  'view.account.login.loginButton': 'Login',

  'view.account.login.loginToast': 'You have successfully logged in.',
  'view.account.login.activationToast': 'Your account has been activated successfully.',
  'view.account.login.resetToast': 'An email with a new password has been handed out for processing.'
}
