export default {
  'app.locale.youAreBrowsingFansiteInText': 'Aktualnie przeglądasz stronę w języku:',
  'app.locale.plText': 'Polski',
  'app.locale.enText': 'Angielski',
  'app.charactersOnline': 'Gracze online',
  'app.boostedCreature': 'Wzmocniony potwór',
  'app.latestFansiteContest': 'Najnowszy konkurs',
  'app.seeMoreIn': 'Więcej znajdziesz w',
  'app.usersText': 'W ciągu ostatnich 15 minut <strong>{viewers}</strong> unikalnych użytkowników przeglądało stronę, w tym <strong>{users}</strong> ',
  'app.usersLoggedInText': 'zalogowanych. | zalogowany. | zalogowanych.'
}
