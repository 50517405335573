export default {
  'view.posts.index.title': 'Posty',

  'view.posts.index.usernameLabel': 'Nazwa',
  'view.posts.index.sortLabel': 'Sortownie',
  'view.posts.index.sortCreatedAtAscOption': 'Data publikacji ― Rosnąco',
  'view.posts.index.sortCreatedAtDescOption': 'Data publikacji ― Malejąco',

  'view.posts.index.postText': 'Post',
  'view.posts.index.forumText': 'Forum',
  'view.posts.index.postedInText': 'napisał w',

  'view.posts.index.postDeletedText': 'Ten post został usunięty przez moderatora lub administratora.',

  'view.posts.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
