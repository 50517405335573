export default {
  'view.rookstat.players.index.title': 'Postacie',

  'view.rookstat.players.index.trendingRookstayerLabel': 'Trendujący Rookstayer',

  'view.rookstat.players.index.trendingRookstayerLevelText': 'Poziom',
  'view.rookstat.players.index.trendingRookstayerGlobalRankText': 'Globalnie',
  'view.rookstat.players.index.trendingRookstayerWorldRankText': 'Na świecie',
  'view.rookstat.players.index.trendingRookstayerWorldText': 'Świat',

  'view.rookstat.players.index.nameLabel': 'Nazwa',

  'view.rookstat.players.index.statusLabel': 'Status',
  'view.rookstat.players.index.statusAllOption': 'Dowolny',

  'view.rookstat.players.index.worldLabel': 'Świat',
  'view.rookstat.players.index.worldAllOption': 'Dowolny',

  'view.rookstat.players.index.sortLabel': 'Sortowanie',

  'view.rookstat.players.index.specialCodesText': 'Możesz skonfigurować swoją postać w rankingu, używając kodów specjalnych.',

  'view.rookstat.players.index.nameText': 'Nazwa',
  'view.rookstat.players.index.worldText': 'Świat',
  'view.rookstat.players.index.accountText': 'Konto',
  'view.rookstat.players.index.scoreText': 'Wynik',

  'view.rookstat.players.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
