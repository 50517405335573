export default {
  'view.account.index.title': 'Account',

  'view.account.index.featuredStatsRankText': 'Rank',
  'view.account.index.featuredStatsCreatedAtText': 'Created At',

  'view.account.index.statsRibbon': 'Stats',
  'view.account.index.statsUsernameText': 'Username',
  'view.account.index.statsEmailText': 'Email',
  'view.account.index.statsWarningLevelText': 'Warning Level',

  'view.account.index.logoutButton': 'Logout',
  'view.account.index.logoutToast': 'You have been successfully logged out.'
}
