export default {
  'view.account.revokeSessions.title': 'Revoke Sessions',

  'view.account.revokeSessions.descriptionText': 'This will log you out from every device you\'re currently logged in, except the current one.',
  'view.account.revokeSessions.passwordLabel': 'Password',

  'view.account.revokeSessions.revokeSessionsButton': 'Revoke Sessions',

  'view.account.revokeSessions.revokeSessionsToast': 'Sessions from all other devices have been revoked successfully.'
}
