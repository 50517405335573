export default {
  'view.account.changeEmail.title': 'Zmień adres e-mail',

  'view.account.changeEmail.newEmailLabel': 'Adres e-mail (nowy)',
  'view.account.changeEmail.confirmPasswordLabel': 'Hasło',

  'view.account.changeEmail.changeButton': 'Zmień adres e-mail',

  'view.account.changeEmail.changeToast': 'Email został zmieniony.'
}
