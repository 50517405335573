export default {
  'view.threads.new.title': 'New Thread',

  'view.threads.new.subjectLabel': 'Subject ({min}-{max} characters)',

  'view.threads.new.forumLabel': 'Forum',
  'view.threads.new.forumContestsEventsOption': 'Contests/Events',
  'view.threads.new.forumReportsProposalsOption': 'Reports/Proposals',
  'view.threads.new.forumGeneralOption': 'General',
  'view.threads.new.forumScreenshotsVideosOption': 'Screenshots/Videos',
  'view.threads.new.forumTradeOption': 'Trade',
  'view.threads.new.forumHelpQuestionsOption': 'Help/Questions',
  'view.threads.new.forumMysteriandoOption': 'Mysteriando',
  'view.threads.new.forumHydeparkOption': 'Hydepark',
  'view.threads.new.forumArchiveOption': 'Archive',
  'view.threads.new.forumArticlesOption': 'Articles',
  'view.threads.new.forumAnnouncementsOption': 'Announcements',

  'view.threads.new.languageLabel': 'Language',
  'view.threads.new.contentLabel': 'Content ({min}-{max} characters)',
  'view.threads.new.previewLabel': 'Preview',

  'view.threads.new.previewButton': 'Preview',
  'view.threads.new.addThreadButton': 'Add Thread',

  'view.threads.new.addThreadToast': 'You have successfully created a new thread.'
}
