export default {
  'view.warnings.new.title': 'New Warning',

  'view.warnings.new.idLabel': 'ID',
  'view.warnings.new.reasonLabel': 'Reason',
  'view.warnings.new.percentsLabel': 'Percents',

  'view.warnings.new.addWarningButton': 'Add Warning',

  'view.warnings.new.addWarningToast': 'You have successfully created a new warning.'
}
