import store from '@/store'

type Auth = {
  id: string
  username: string
  roles: string[]
  banned: boolean
  token: string
  authenticated: boolean
}

export const auth: Auth = store.state.auth

export const login = async (auth: Auth): Promise<void> => {
  return await store.dispatch('LOGIN', auth)
}

export const logout = async (): Promise<void> => {
  return await store.dispatch('LOGOUT')
}
