export default {
  state: {
    statusCodes: {}
  },
  getters: {
    getStatusCode: ({ statusCodes }) => path => statusCodes[path] ?? 200
  },
  mutations: {
    SET_STATUS_CODE: (state, statusCode) => {
      state.statusCodes = Object.assign({}, state.statusCodes, statusCode)
    }
  }
}
