export default {
  'view.library.npcs.index.title': 'NPCs',

  'view.library.npcs.index.nameLabel': 'Name',

  'view.library.npcs.index.sortLabel': 'Sort',
  'view.library.npcs.index.sortNameAscOption': 'Name ― Ascending',
  'view.library.npcs.index.sortNameDescOption': 'Name ― Descending',

  'view.library.npcs.index.nameText': 'Name',
  'view.library.npcs.index.jobText': 'Job',
  'view.library.npcs.index.locationText': 'Location',

  'view.library.npcs.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
