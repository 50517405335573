export default {
  'view.account.changePassword.title': 'Change Password',

  'view.account.changePassword.newPasswordLabel': 'Password (New)',
  'view.account.changePassword.confirmPasswordLabel': 'Password (Current)',

  'view.account.changePassword.changeButton': 'Change Password',

  'view.account.changePassword.changeToast': 'Your password has been changed successfully.'
}
