export default {
  'view.rookstat.worlds.title': 'Worlds',

  'view.rookstat.worlds.nameLabel': 'Name',

  'view.rookstat.worlds.locationLabel': 'Location',
  'view.rookstat.worlds.locationAllOption': 'All Locations',
  'view.rookstat.worlds.locationEuropeOption': 'Europe',
  'view.rookstat.worlds.locationNorthAmericaOption': 'North America',
  'view.rookstat.worlds.locationSouthAmericaOption': 'South America',
  'view.rookstat.worlds.locationOceaniaOption': 'Oceania',

  'view.rookstat.worlds.sortLabel': 'Sort',
  'view.rookstat.worlds.sortNameAscOption': 'Name ― Ascending',
  'view.rookstat.worlds.sortNameDescOption': 'Name ― Descending',

  'view.rookstat.worlds.nameText': 'Name',
  'view.rookstat.worlds.battleyeText': 'BattlEye',
  'view.rookstat.worlds.playersText': 'Players',
  'view.rookstat.worlds.maxLevelText': 'Max Level',
  'view.rookstat.worlds.avgLevelText': 'Avg Level',
  'view.rookstat.worlds.pvpTypeText': 'PvP Type',

  'view.rookstat.worlds.emptyListText': 'Sorry, but no results match your search criteria.'
}
