export default {
  'view.library.items.index.title': 'Items',

  'view.library.items.index.nameLabel': 'Name',

  'view.library.items.index.sortLabel': 'Sort',
  'view.library.items.index.sortNameAscOption': 'Name ― Ascending',
  'view.library.items.index.sortNameDescOption': 'Name ― Descending',

  'view.library.items.index.sortAscText': 'Ascending',
  'view.library.items.index.sortDescText': 'Descending',

  'view.library.items.index.nameText': 'Name',
  'view.library.items.index.weightText': 'Weight (oz.)',
  'view.library.items.index.volumeText': 'Volume',
  'view.library.items.index.protectionEarthText': 'Protection (Earth)',
  'view.library.items.index.protectionLifeDrainText': 'Protection (Life Drain)',
  'view.library.items.index.protectionManaDrainText': 'Protection (Mana Drain)',
  'view.library.items.index.durationText': 'Duration (Seconds)',
  'view.library.items.index.armorText': 'Armor',
  'view.library.items.index.attackText': 'Attack',
  'view.library.items.index.defenseText': 'Defense',
  'view.library.items.index.defenseModifierText': 'Defense Modifier',
  'view.library.items.index.rangeText': 'Range',

  'view.library.items.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
