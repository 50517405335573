export default {
  'view.calculators.ageCalculator.title': 'Age Calculator',

  'view.calculators.ageCalculator.dayLabel': 'Day',
  'view.calculators.ageCalculator.monthLabel': 'Month',
  'view.calculators.ageCalculator.yearLabel': 'Year',

  'view.calculators.ageCalculator.calculateButton': 'Calculate',

  'view.calculators.ageCalculator.ageText': 'Age',
  'view.calculators.ageCalculator.ageInTibiaText': 'Age in Tibia',
  'view.calculators.ageCalculator.tibiaVersionText': 'Tibia Version'
}
