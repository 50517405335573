export default {
  'view.library.npcs.npc.jobText': 'Job',
  'view.library.npcs.npc.locationText': 'Location',
  'view.library.npcs.npc.piecesText': 'pieces | piece | pieces',

  'view.library.npcs.npc.buyTab': 'Buy',

  'view.library.npcs.npc.buyNoItemsText': 'This NPC does not buy any items.',

  'view.library.npcs.npc.sellTab': 'Sell',

  'view.library.npcs.npc.sellNoItemsText': 'This NPC has no items to sell.'
}
