import { v4 as uuidv4 } from 'uuid'

const timeout = 5000

export default {
  state: {
    toasts: []
  },
  mutations: {
    ADD_TOAST: (state, toast) => {
      toast.id = uuidv4()

      if (state.toasts.length >= 3) {
        state.toasts = state.toasts.slice(0, 2)
      }

      state.toasts.unshift(toast)
    },
    REMOVE_TOAST: (state, id) => {
      state.toasts = state.toasts.filter(toast => toast.id !== id)
    }
  },
  actions: {
    TOAST: (context, toast) => {
      if (!context.state.toasts.some(({ message }) => message === toast.message)) {
        context.commit('ADD_TOAST', toast)

        const timeoutable = toast.timeoutable ?? true

        if (timeoutable) {
          setTimeout(() => context.commit('REMOVE_TOAST', toast.id), timeout)
        }
      }
    }
  }
}
