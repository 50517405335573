export default {
  'view.calculators.partyCalculator.title': 'Kalkulator zasięgu imprezy',

  'view.calculators.partyCalculator.levelLabel': 'Poziom ({min}-{max})',

  'view.calculators.partyCalculator.calculateButton': 'Oblicz',

  'view.calculators.partyCalculator.minLevelText': 'Najmniejszy poziom',
  'view.calculators.partyCalculator.maxLevelText': 'Największy poziom'
}
