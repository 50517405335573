export default {
  'view.users.index.title': 'Użytkownicy',

  'view.users.index.usernameLabel': 'Nazwa',

  'view.users.index.roleLabel': 'Rola',
  'view.users.index.roleAllOption': 'Dowolna',
  'view.users.index.roleUserOption': 'Użytkownik',
  'view.users.index.roleBloggerOption': 'Bloger',
  'view.users.index.roleEventManagerOption': 'Event Manager',
  'view.users.index.roleModeratorOption': 'Moderator',
  'view.users.index.roleAdminOption': 'Admin',

  'view.users.index.sortLabel': 'Sortowanie',
  'view.users.index.sortCreatedAtAscOption': 'Dołączył ― Rosnąco',
  'view.users.index.sortCreatedAtDescOption': 'Dołączył ― Malejąco',
  'view.users.index.sortUsernameAscOption': 'Nazwa ― Rosnąco',
  'view.users.index.sortUsernameDescOption': 'Nazwa ― Malejąco',
  'view.users.index.sortCommentsAscOption': 'Komentarze ― Rosnąco',
  'view.users.index.sortCommentsDescOption': 'Komentarze ― Malejąco',
  'view.users.index.sortPostsAscOption': 'Posty ― Rosnąco',
  'view.users.index.sortPostsDescOption': 'Posty ― Malejąco',
  'view.users.index.sortThreadsAscOption': 'Wątki ― Rosnąco',
  'view.users.index.sortThreadsDescOption': 'Wątki ― Malejąco',
  'view.users.index.sortImagesAscOption': 'Obrazki ― Rosnąco',
  'view.users.index.sortImagesDescOption': 'Obrazki ― Malejąco',
  'view.users.index.sortActivityPointsAscOption': 'Pkt. aktywności ― Rosnąco',
  'view.users.index.sortActivityPointsDescOption': 'Pkt. aktywności ― Malejąco',

  'view.users.index.usernameText': 'Nazwa',
  'view.users.index.commentsText': 'Komentarze',
  'view.users.index.postsText': 'Posty',
  'view.users.index.threadsText': 'Wątki',
  'view.users.index.imagesText': 'Obrazki',
  'view.users.index.activityPointsText': 'Pkt. aktywności',
  'view.users.index.createdAtText': 'Dołączył',

  'view.users.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
