export default {
  'view.calculators.partyCalculator.title': 'Party Calculator',

  'view.calculators.partyCalculator.levelLabel': 'Level ({min}-{max})',

  'view.calculators.partyCalculator.calculateButton': 'Calculate',

  'view.calculators.partyCalculator.minLevelText': 'Min Level',
  'view.calculators.partyCalculator.maxLevelText': 'Max Level'
}
