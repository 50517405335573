export default {
  'view.images.index.title': 'Galeria',

  'view.images.index.usernameLabel': 'Nazwa',

  'view.images.index.tagLabel': 'Tag',
  'view.images.index.tagAllOption': 'Dowolny',
  'view.images.index.tagAdvancementOption': 'Awans',
  'view.images.index.tagBossOption': 'Boss',
  'view.images.index.tagCollectionOption': 'Kolekcja',
  'view.images.index.tagEventOption': 'Wydarzenie',
  'view.images.index.tagFansiteEventOption': 'Fansite Event',
  'view.images.index.tagFriendsOption': 'Przyjaciele',
  'view.images.index.tagFunnyOption': 'Śmieszne',
  'view.images.index.tagLootOption': 'Loot',
  'view.images.index.tagOldTibiaOption': 'Stara Tibia',
  'view.images.index.tagTrapandoOption': 'Trapando',
  'view.images.index.tagOtherOption': 'Inne',
  'view.images.index.tagBlogRookieTeamOption': 'Blog (Rookie Team)',
  'view.images.index.tagOtherRookieTeamOption': 'Inne (Rookie Team)',

  'view.images.index.sortLabel': 'Sortowanie',
  'view.images.index.sortCreatedAtAscOption': 'Utworzono ― Rosnąco',
  'view.images.index.sortCreatedAtDescOption': 'Utworzono ― Malejąco',

  'view.images.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
