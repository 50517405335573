export default {
  'view.blog.edit.title': 'Edytuj Blog',

  'view.blog.edit.titleLabel': 'Tytuł',
  'view.blog.edit.tagLabel': 'Tag',

  'view.blog.edit.tagArticleOption': 'Artykuł',
  'view.blog.edit.tagExclusiveOption': 'Exclusive',
  'view.blog.edit.tagFansiteDevOption': 'Fansite Dev',
  'view.blog.edit.tagFeaturedTheRookstayerOption': 'The Rookstayer',
  'view.blog.edit.tagFeaturedAdvancementsOption': 'Advancements',
  'view.blog.edit.tagInterviewOption': 'Wywiad',
  'view.blog.edit.tagNewsOption': 'Newsy',
  'view.blog.edit.tagTutorialOption': 'Poradnik',

  'view.blog.edit.languageLabel': 'Język',

  'view.blog.edit.featuredLabel': 'Polecany',

  'view.blog.edit.featuredYesOption': 'Tak',
  'view.blog.edit.featuredNoOption': 'Nie',

  'view.blog.edit.contentLabel': 'Treść',

  'view.blog.edit.previewButton': 'Podgląd',
  'view.blog.edit.editBlogButton': 'Edytuj Blog',

  'view.blog.edit.updateBlogToast': 'Udało ci się zaktualizować blog.'
}
