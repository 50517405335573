export default {
  'view.threads.edit.title': 'Edytuj wątek',

  'view.threads.edit.subjectLabel': 'Temat ({min}-{max} znaków)',
  'view.threads.edit.contentLabel': 'Treść ({min}-{max} znaków)',
  'view.threads.edit.previewLabel': 'Pogląd',

  'view.threads.edit.previewButton': 'Podgląd',
  'view.threads.edit.editThreadButton': 'Edytuj wątek',

  'view.threads.edit.editThreadToast': 'Pomyślnie zedytowałeś wątek.'
}
