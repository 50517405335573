export default {
  'view.account.resetPassword.title': 'Reset Password',

  'view.account.resetPassword.usernameLabel': 'Username',
  'view.account.resetPassword.emailLabel': 'Email',

  'view.account.resetPassword.sendPassworResetLinkButton': 'Send Password Reset Link',

  'view.account.resetPassword.resetToast': 'A password reset link has been sent out.'
}
