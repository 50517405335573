export default {
  'view.warnings.new.title': 'Nowe ostrzeżenie',

  'view.warnings.new.idLabel': 'ID',
  'view.warnings.new.reasonLabel': 'Powód',
  'view.warnings.new.percentsLabel': 'Procenty',

  'view.warnings.new.addWarningButton': 'Dodaj ostrzeżenie',

  'view.warnings.new.addWarningToast': 'Pomyślnie dodałeś nowe ostrzeżenie.'
}
