export default {
  component: {
    pager: {
      beforeEdgesText: 'Wyświetlam',
      betweenEdgesText: '―',
      afterEdgesText: 'spośród',
      resultsText: 'wyniku | wyników'
    }
  }
}
