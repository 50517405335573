export default {
  'view.library.books.index.title': 'Books',

  'view.library.books.index.searchLabel': 'Search',

  'view.library.books.index.islandLabel': 'Island',
  'view.library.books.index.islandAllOption': 'All',

  'view.library.books.index.sortLabel': 'Sort',
  'view.library.books.index.sortTitleAscOption': 'Title ― Ascending',
  'view.library.books.index.sortTitleDescOption': 'Title ― Descending',
  'view.library.books.index.sortLocationAscOption': 'Location ― Ascending',
  'view.library.books.index.sortLocationDescOption': 'Location ― Descending',

  'view.library.books.index.titleText': 'Title',
  'view.library.books.index.locationText': 'Location',

  'view.library.books.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
