export default {
  'view.images.image.uploadedByText': 'Uploaded By',
  'view.images.image.uploadedAtText': 'Uploaded At',

  'view.images.image.sourceLabel': 'Source',
  'view.images.image.bbcodeLabel': 'BBCode',

  'view.images.image.deleteButton': 'Delete',

  'view.images.image.deleteImageConfirm': 'Are you sure you want to delete this image?',
  'view.images.image.deleteImageToast': 'You have successfully deleted an image.'
}
