export default {
  state: {
    locale: null
  },
  mutations: {
    SET_LOCALE: (state, locale) => {
      state.locale = locale
    }
  }
}
