export default {
  'view.library.books.index.title': 'Książki',

  'view.library.books.index.searchLabel': 'Szukaj',

  'view.library.books.index.islandLabel': 'Wyspa',
  'view.library.books.index.islandAllOption': 'Dowolna',

  'view.library.books.index.sortLabel': 'Sortowanie',
  'view.library.books.index.sortTitleAscOption': 'Tytuł ― Rosnąco',
  'view.library.books.index.sortTitleDescOption': 'Tytuł ― Malejąco',
  'view.library.books.index.sortLocationAscOption': 'Lokalizacja ― Rosnąco',
  'view.library.books.index.sortLocationDescOption': 'Lokalizacja ― Malejąco',

  'view.library.books.index.titleText': 'Tytuł',
  'view.library.books.index.locationText': 'Lokalizacja',

  'view.library.books.index.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
