import router from '@/router'
import store from '@/store'

export const getTitle = (path?: string): string => {
  path = path ?? router.currentRoute.value.path

  return store.getters.getRouteTitle(path)
}

export const setTitle = (routeTitle: string, path?: string): Promise<void> => {
  path = path ?? router.currentRoute.value.path

  return Promise.resolve(
    store.commit('SET_ROUTE_TITLE', { path, routeTitle })
  )
}

export const setSlug = async (slug: string, path?: string): Promise<void> => {
  router.replace(
    Object.assign({}, path ?? router.currentRoute.value, {
      params: {
        slug
      }
    })
  )

  return Promise.resolve()
}
