export default {
  'view.account.anonymize.title': 'Anonymize',

  'view.account.anonymize.descriptionText': 'Anonymizing means your username and email will change into unrecognizable alias and your profile will no longer be available.',

  'view.account.anonymize.confirmPasswordLabel': 'Password',
  'view.account.anonymize.agreementLabel': 'I\'m aware this action cannot be undone.',

  'view.account.anonymize.anonymizeButton': 'Anonymize',

  'view.account.anonymize.anonymizeToast': 'You have been successfully anonymized.'
}
