export default {
  'view.calculators.playerStatsCalculator.title': 'Player Stats Calculator',

  'view.calculators.playerStatsCalculator.levelLabel': 'Level ({min}-{max})',
  'view.calculators.playerStatsCalculator.vocationLabel': 'Vocation',

  'view.calculators.playerStatsCalculator.calculateButton': 'Calculate',

  'view.calculators.playerStatsCalculator.hitpointsText': 'Hitpoints',
  'view.calculators.playerStatsCalculator.manaText': 'Mana',
  'view.calculators.playerStatsCalculator.capacityText': 'Capacity',
  'view.calculators.playerStatsCalculator.speedText': 'Speed'
}
