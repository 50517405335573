export default {
  'view.account.warnings.title': 'Ostrzeżenia',

  'view.account.warnings.sortLabel': 'Sortowanie',
  'view.account.warnings.sortPercentsAscOption': 'Procenty ― Rosnąco',
  'view.account.warnings.sortPercentsDescOption': 'Procenty ― Malejąco',
  'view.account.warnings.sortReasonAscOption': 'Powód ― Rosnąco',
  'view.account.warnings.sortReasonDescOption': 'Powód ― Malejąco',

  'view.account.warnings.reasonText': 'Powód',
  'view.account.warnings.issuedByText': 'Przez',
  'view.account.warnings.percentsText': 'Procenty',
  'view.account.warnings.statusText': 'Status',

  'view.account.warnings.statusPermanentText': 'Permanentny',
  'view.account.warnings.statusExpiredText': 'Wygasły',
  'view.account.warnings.statusActiveText': 'Aktywny',

  'view.account.warnings.emptyListText': 'Przykro nam, ale żaden wynik nie spełnia twoich kryteriów wyszukiwania.'
}
