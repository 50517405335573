export default {
  'view.threads.thread.repliesText': 'Replies',
  'view.threads.thread.languageText': 'Language',

  'view.threads.thread.statusText': 'Status',
  'view.threads.thread.statusOpenText': 'Open',
  'view.threads.thread.statusClosedText': 'Closed',

  'view.threads.thread.pinnedText': 'Pinned',
  'view.threads.thread.pinnedYesText': 'Yes',
  'view.threads.thread.pinnedNoText': 'No',

  'view.threads.thread.postedText': 'posted',
  'view.threads.thread.threadsText': 'threads',
  'view.threads.thread.postsText': 'posts',

  'view.threads.thread.citeText': 'Cite',

  'view.threads.thread.doYouLikeThisPostText': 'Do you like the post?',

  'view.threads.thread.moderationBeforePinUnpinText': 'As a moderator, you can',
  'view.threads.thread.moderationPinText': 'pin',
  'view.threads.thread.moderationUnpinText': 'unpin',
  'view.threads.thread.moderationBetweenPinUnpinAndLockUnlockText': 'and/or',
  'view.threads.thread.moderationLockText': 'lock',
  'view.threads.thread.moderationUnlockText': 'unlock',
  'view.threads.thread.moderationAfterLockUnlockText': 'this thread',

  'view.threads.thread.deleteThreadConfirm': 'Are you sure you want to delete this thread?',
  'view.threads.thread.deleteThreadToast': 'You have successfully deleted a post.',

  'view.threads.thread.postDeletedText': 'This post has been deleted by a moderator or administrator.',

  'view.threads.thread.deletePostConfirm': 'Are you sure you want to delete this post?',
  'view.threads.thread.deletePostToast': 'You have successfully deleted a post.'
}
