export default {
  'view.images.edit.title': 'Edytuj obrazek',

  'view.images.edit.fileLabel': 'Plik',

  'view.images.edit.tagLabel': 'Tag',
  'view.images.edit.tagAdvancementOption': 'Awans',
  'view.images.edit.tagBossOption': 'Boss',
  'view.images.edit.tagCollectionOption': 'Kolekcja',
  'view.images.edit.tagEventOption': 'Wydarzenie',
  'view.images.edit.tagFansiteEventOption': 'Fansite Event',
  'view.images.edit.tagFriendsOption': 'Przyjaciele',
  'view.images.edit.tagFunnyOption': 'Śmieszne',
  'view.images.edit.tagLootOption': 'Loot',
  'view.images.edit.tagOldTibiaOption': 'Stara Tibia',
  'view.images.edit.tagTrapandoOption': 'Trapando',
  'view.images.edit.tagBlogRookieTeamOption': 'Blog (Rookie Team)',
  'view.images.edit.tagOtherRookieTeamOption': 'Inne (Rookie Team)',

  'view.images.edit.editButton': 'Edytuj',

  'view.images.image.editImageToast': 'Pomyślnie edytowałeś obrazek.'
}
