import { createApp } from 'vue'

import App from '@/App.vue'

import gtag from 'vue-gtag-next'

import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'

import { isBrowser } from '@/lib/platform'

import $axios from '@/plugins/axios'
import $can from '@/plugins/can'
import $slugify from '@/plugins/slugify'

import '@/library'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default () => {
  const app = createApp(App)

  app
    .component('fa-icon', FontAwesomeIcon)

    .use(gtag, {
      property: [
        { id: process.env.VUE_APP_GTM_UA },
        { id: process.env.VUE_APP_GTM_GA4 }
      ],
      isEnabled: isBrowser()
    })

    .use(i18n)
    .use(router)
    .use(store)

    .use($axios)
    .use($can)
    .use($slugify)

  return { app, router, store, i18n }
}
