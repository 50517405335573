export default {
  'view.rookstat.boostedCreatures.title': 'Boosted Creatures',

  'view.rookstat.boostedCreatures.searchLabel': 'Search',
  'view.rookstat.boostedCreatures.sortLabel': 'Sort',
  'view.rookstat.boostedCreatures.sortDateAscOption': 'Created At ― Ascending',
  'view.rookstat.boostedCreatures.sortDateDescOption': 'Created At ― Descending',

  'view.rookstat.boostedCreatures.nameText': 'Name',
  'view.rookstat.boostedCreatures.dateText': 'Date',

  'view.rookstat.boostedCreatures.emptyListText': 'Sorry, but no results match your search criteria.'
}
