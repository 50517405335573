export default {
  'view.threads.editPost.title': 'Edit post',

  'view.threads.editPost.contentLabel': 'Content ({min}-{max} characters)',
  'view.threads.editPost.previewLabel': 'Preview',

  'view.threads.editPost.previewButton': 'Preview',
  'view.threads.editPost.editPostButton': 'Edit post',

  'view.threads.editPost.editPostToast': 'You have successfully edited post.'
}
