export default {
  'view.users.user.featuredStatsRankText': 'Ranga',
  'view.users.user.featuredStatsActivityPointsText': 'Pkt. aktywności',
  'view.users.user.featuredStatsCreatedAtText': 'Dołączył',

  'view.users.user.statsTab': 'Statystyki',

  'view.users.user.statsBlogsText': 'Blogi',
  'view.users.user.statsCommentsText': 'Komentarze',
  'view.users.user.statsThreadsText': 'Wątki',
  'view.users.user.statsPostsText': 'Posty',
  'view.users.user.statsImagesText': 'Obrazki',
  'view.users.user.statsWarningLevelText': 'Ostrzeżenia',

  'view.users.user.characterTab': 'Postać',

  'view.users.user.characterNameText': 'Nazwa',
  'view.users.user.characterWorldText': 'Świat',
  'view.users.user.characterLevelText': 'Poziom',
  'view.users.user.characterAchievementPointsText': 'Punkty osiągnięć',

  'view.users.user.buttonsPostsText': 'Posty',
  'view.users.user.buttonsImagesText': 'Obrazki',
  'view.users.user.buttonsWarnText': 'Ostrzeż'
}
