export default {
  'view.blog.new.title': 'Nowy Blog',

  'view.blog.new.titleLabel': 'Tytuł',
  'view.blog.new.tagLabel': 'Tag',

  'view.blog.new.tagArticleOption': 'Artykuł',
  'view.blog.new.tagExclusiveOption': 'Exclusive',
  'view.blog.new.tagFansiteDevOption': 'Fansite Dev',
  'view.blog.new.tagFeaturedTheRookstayerOption': 'The Rookstayer',
  'view.blog.new.tagFeaturedAdvancementsOption': 'Advancements',
  'view.blog.new.tagInterviewOption': 'Wywiad',
  'view.blog.new.tagNewsOption': 'Newsy',
  'view.blog.new.tagTutorialOption': 'Poradnik',

  'view.blog.new.languageLabel': 'Język',

  'view.blog.new.featuredLabel': 'Polecany',

  'view.blog.new.featuredYesOption': 'Tak',
  'view.blog.new.featuredNoOption': 'Nie',

  'view.blog.new.contentLabel': 'Treść',
  'view.blog.new.previewLabel': 'Podgląd',

  'view.blog.new.previewButton': 'Podgląd',
  'view.blog.new.addBlogButton': 'Dodaj bloga',

  'view.blog.new.createBlogToast': 'Udało ci się opublikować bloga.'
}
