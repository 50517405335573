export default {
  'view.calculators.ageCalculator.title': 'Kalkulator wieku',

  'view.calculators.ageCalculator.dayLabel': 'Dzień',
  'view.calculators.ageCalculator.monthLabel': 'Miesiac',
  'view.calculators.ageCalculator.yearLabel': 'Rok',

  'view.calculators.ageCalculator.calculateButton': 'Oblicz',

  'view.calculators.ageCalculator.ageText': 'Wiek',
  'view.calculators.ageCalculator.ageInTibiaText': 'Wiek Tibii',
  'view.calculators.ageCalculator.tibiaVersionText': 'Wersja Tibii'
}
