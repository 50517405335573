import { App } from 'vue'

import slugify from 'slugify'

export default {
  install: (app: App): void => {
    app.config.globalProperties.$slugify = (string: string): string => {
      return slugify(string, {
        lower: true,
        remove: /[*+~.()'"!:@]/g
      })
    }
  }
}
