export default {
  'view.threads.thread.repliesText': 'Odpowiedzi',
  'view.threads.thread.languageText': 'Język',

  'view.threads.thread.statusText': 'Status',
  'view.threads.thread.statusOpenText': 'Otwarty',
  'view.threads.thread.statusClosedText': 'Zamknięty',

  'view.threads.thread.pinnedText': 'Przypięty',
  'view.threads.thread.pinnedYesText': 'Tak',
  'view.threads.thread.pinnedNoText': 'Nie',

  'view.threads.thread.postedText': 'napisał',
  'view.threads.thread.threadsText': 'wątków',
  'view.threads.thread.postsText': 'postów',

  'view.threads.thread.citeText': 'Zacytuj',

  'view.threads.thread.doYouLikeThisPostText': 'Czy podoba ci się ten post?',

  'view.threads.thread.moderationBeforePinUnpinText': 'Jako moderator możesz',
  'view.threads.thread.moderationPinText': 'przypiąć',
  'view.threads.thread.moderationUnpinText': 'odpiąć',
  'view.threads.thread.moderationBetweenPinUnpinAndLockUnlockText': 'lub',
  'view.threads.thread.moderationLockText': 'zamknąć',
  'view.threads.thread.moderationUnlockText': 'otworzyć',
  'view.threads.thread.moderationAfterLockUnlockText': 'ten wątek',

  'view.threads.thread.deleteThreadConfirm': 'Jesteś pewien, że chcesz usunąć ten wątek?',
  'view.threads.thread.deleteThreadToast': 'Pomyślnie usunąłeś wątek.',

  'view.threads.thread.postDeletedText': 'Ten post został usunięty przez moderatora lub administratora.',

  'view.threads.thread.deletePostConfirm': 'Jesteś pewien, że chcesz usunąć ten post?',
  'view.threads.thread.deletePostToast': 'Pomyślnie usunąłeś post.'
}
