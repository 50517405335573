export default {
  'app.locale.youAreBrowsingFansiteInText': 'You are currently browsing the fansite in:',
  'app.locale.plText': 'Polish',
  'app.locale.enText': 'English',
  'app.charactersOnline': 'Characters Online',
  'app.boostedCreature': 'Boosted Creature',
  'app.latestFansiteContest': 'Latest Fansite Contest',
  'app.seeMoreIn': 'See more in',
  'app.usersText': 'In the last 15 minutes, <strong>{viewers}</strong> unique users visited the fansite, including <strong>{users}</strong> ',
  'app.usersLoggedInText': 'logged in.'
}
