export default {
  'view.account.warnings.title': 'Warnings',

  'view.account.warnings.sortLabel': 'Sort',
  'view.account.warnings.sortPercentsAscOption': 'Percents ― Ascending',
  'view.account.warnings.sortPercentsDescOption': 'Percents ― Descending',
  'view.account.warnings.sortReasonAscOption': 'Reason ― Ascending',
  'view.account.warnings.sortReasonDescOption': 'Reason ― Descending',

  'view.account.warnings.reasonText': 'Reason',
  'view.account.warnings.issuedByText': 'Issued By',
  'view.account.warnings.percentsText': 'Percents',
  'view.account.warnings.statusText': 'Status',

  'view.account.warnings.statusPermanentText': 'Permanent',
  'view.account.warnings.statusExpiredText': 'Expired',
  'view.account.warnings.statusActiveText': 'Active',

  'view.account.warnings.emptyListText': 'Sorry, but no results match your search criteria.'
}
