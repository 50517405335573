export default {
  'view.account.register.title': 'Register',

  'view.account.register.usernameLabel': 'Username ({min}-{max} characters)',
  'view.account.register.emailLabel': 'Email',
  'view.account.register.passwordLabel': 'Password',
  'view.account.register.repeatPasswordLabel': 'Repeat Password',
  'view.account.register.securityPasswordLabel': 'What is the name of novice island?',
  'view.account.register.agreementBeforeRulesLabel': 'I accept the',
  'view.account.register.agreementAfterRulesBeforePrivacyPolicyLabel': 'and agree to the',

  'view.account.register.repeatPasswordHelp': 'Passwords do not match.',

  'view.account.register.registerButton': 'Register',

  'view.account.register.registerToast': 'Your account has been created successfully and email with activation link has been sent out.'
}
