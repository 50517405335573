export default {
  'view.posts.index.title': 'Posts',

  'view.posts.index.usernameLabel': 'Username',
  'view.posts.index.sortLabel': 'Sort',
  'view.posts.index.sortCreatedAtAscOption': 'Created At ― Ascending',
  'view.posts.index.sortCreatedAtDescOption': 'Created At ― Descending',

  'view.posts.index.postText': 'Post',
  'view.posts.index.forumText': 'Forum',
  'view.posts.index.postedInText': 'posted in',

  'view.posts.index.postDeletedText': 'This post has been deleted by a moderator or administrator.',

  'view.posts.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
