export default {
  'view.rookstat.advancements.title': 'Advancements',

  'view.rookstat.advancements.nameLabel': 'Name',
  'view.rookstat.advancements.worldLabel': 'World',
  'view.rookstat.advancements.worldAllOption': 'All Worlds',
  'view.rookstat.advancements.typeLabel': 'Type',
  'view.rookstat.advancements.sortLabel': 'Sort',
  'view.rookstat.advancements.sortCreatedAtAscOption': 'Created At ― Ascending',
  'view.rookstat.advancements.sortCreatedAtDescOption': 'Created At ― Descending',
  'view.rookstat.advancements.sortValueAscOption': 'Value ― Ascending',
  'view.rookstat.advancements.sortValueDescOption': 'Value ― Descending',

  'view.rookstat.advancements.nameText': 'Name',
  'view.rookstat.advancements.advancementText': 'Advancement',
  'view.rookstat.advancements.dateText': 'Date',

  'view.rookstat.advancements.emptyListText': 'Sorry, but no results match your search criteria.'
}
