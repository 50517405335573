import { createStore } from 'vuex'

import auth from '@/store/modules/authModule'
import lang from '@/store/modules/langModule'
import locale from '@/store/modules/localeModule'
import route from '@/store/modules/routeModule'
import statusCode from '@/store/modules/statusCodeModule'
import toast from '@/store/modules/toastModule'

import cookiePlugin from '@/store/plugins/cookiePlugin'
import localStoragePlugin from '@/store/plugins/localStoragePlugin'

const authExpiresFn = () => {
  return localStorage.getItem('rememberMe') === 'true' ? 365 : undefined
}

export default createStore({
  modules: {
    auth,
    lang,
    locale,
    route,
    statusCode,
    toast
  },
  plugins: [
    cookiePlugin([
      { path: 'auth.id', expires: () => authExpiresFn() },
      { path: 'auth.username', expires: () => authExpiresFn() },
      { path: 'auth.roles', expires: () => authExpiresFn() },
      { path: 'auth.token', expires: () => authExpiresFn() },
      { path: 'auth.authenticated', expires: () => authExpiresFn() }
    ]),
    localStoragePlugin([
      'lang.language',
      'locale.locale'
    ])
  ]
})
