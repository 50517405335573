export default {
  'view.account.login.title': 'Zaloguj',

  'view.account.login.usernameLabel': 'Nazwa',
  'view.account.login.passwordLabel': 'Hasło',
  'view.account.login.keepMeLoggedInLabel': 'Pozostań zalogowanym.',

  'view.account.login.loginButton': 'Zaloguj',

  'view.account.login.loginToast': 'Zalogowałeś się pomyślnie.',
  'view.account.login.activationToast': 'Twoje konto zostało pomyślnie aktywowane.',
  'view.account.login.resetToast': 'Wiadomość email została wysłana.'
}
