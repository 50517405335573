export default {
  'view.library.npcs.npc.jobText': 'Profesja',
  'view.library.npcs.npc.locationText': 'Lokacja',
  'view.library.npcs.npc.piecesText': 'szt.',

  'view.library.npcs.npc.buyTab': 'Kupno',

  'view.library.npcs.npc.buyNoItemsText': 'Ten NPC nie kupuje żadnych przedmiotów.',

  'view.library.npcs.npc.sellTab': 'Sprzedaż',

  'view.library.npcs.npc.sellNoItemsText': 'Ten NPC nie ma żadnych przedmiotów na sprzedaż.'
}
