<template>
  <template v-if="src">
    <img @error="onError" @load="showImage" :src="src" :alt="alt" :style="style"/>
  </template>
  <template v-else-if="image">
    <img @error="onError" @load="showImage" :src="resolveSrc(image)" :alt="alt" :style="style"/>
  </template>
  <template v-else>
    <img @load="showImage" src="@/assets/images/Thumbnail.png" :style="style"/>
  </template>
</template>

<script>
export default {
  name: 'v-image',
  props: {
    src: String,
    image: String,
    width: Number,
    height: Number,
    alt: String
  },
  computed: {
    style () {
      const styles = []

      if (this.width) {
        styles.push(`width: ${this.width}px;`)
      }

      if (this.height) {
        styles.push(`height: ${this.height}px;`)
      }

      return styles.join(' ')
    }
  },
  methods: {
    resolveSrc (image) {
      try {
        return require(`@/assets/images/${image}`)
      } catch {
        return require('@/assets/images/Thumbnail.png')
      }
    },
    onError ({ target }) {
      target.src = require('@/assets/images/Thumbnail.png')
    },
    showImage ({ target }) {
      target.classList.add('is-loaded')
    }
  }
}
</script>
