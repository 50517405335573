export default {
  'view.rookstat.deaths.title': 'Deaths',

  'view.rookstat.deaths.nameLabel': 'Name',

  'view.rookstat.deaths.worldLabel': 'World',
  'view.rookstat.deaths.worldAllOption': 'All',

  'view.rookstat.deaths.sortLabel': 'Sort',
  'view.rookstat.deaths.sortDiedAtAscOption': 'Died At ― Ascending',
  'view.rookstat.deaths.sortDiedAtDescOption': 'Died At ― Descending',

  'view.rookstat.deaths.nameText': 'Name',
  'view.rookstat.deaths.worldText': 'World',
  'view.rookstat.deaths.reasonText': 'Reason',
  'view.rookstat.deaths.dateText': 'Date',

  'view.rookstat.deaths.emptyListText': 'Sorry, but no results match your search criteria.'
}
