export default {
  'view.library.items.item.categoryText': 'Category',
  'view.library.items.item.categoryContainerText': 'Container',
  'view.library.items.item.categoryAmuletText': 'Amulet',
  'view.library.items.item.categoryRingText': 'Ring',
  'view.library.items.item.categoryHelmetText': 'Helmet',
  'view.library.items.item.categoryArmorText': 'Armor',
  'view.library.items.item.categoryLegsText': 'Legs',
  'view.library.items.item.categoryBootsText': 'Boots',
  'view.library.items.item.categoryMeleeWeaponText': 'Melee Weapon',
  'view.library.items.item.categoryAxeText': 'Axe',
  'view.library.items.item.categoryClubText': 'Club',
  'view.library.items.item.categorySwordText': 'Sword',
  'view.library.items.item.categoryDistanceWeaponText': 'Distance Weapon',
  'view.library.items.item.categoryShieldText': 'Shield',
  'view.library.items.item.categoryToolText': 'Tool',
  'view.library.items.item.categoryFoodText': 'Food',
  'view.library.items.item.categoryLiquidText': 'Liquid',
  'view.library.items.item.categoryCreatureProductText': 'Creature Product',
  'view.library.items.item.categoryCurrencyText': 'Currency',

  'view.library.items.item.statsTab': 'Stats',

  'view.library.items.item.statsVolumeText': 'Volume',
  'view.library.items.item.statsWeightText': 'Weight (oz.)',
  'view.library.items.item.statsProtectionEarthText': 'Protection (Earth)',
  'view.library.items.item.statsProtectionLifeDrainText': 'Protection (Life Drain)',
  'view.library.items.item.statsProtectionManaDrainText': 'Protection (Mana Drain)',
  'view.library.items.item.statsDurationText': 'Duration (Seconds)',
  'view.library.items.item.statsArmorText': 'Armor',
  'view.library.items.item.statsAttackText': 'Attack',
  'view.library.items.item.statsDefenseText': 'Defense',
  'view.library.items.item.statsDefenseModifierText': 'Defense Modifier',
  'view.library.items.item.statsRangeText': 'Range',

  'view.library.items.item.lootTab': 'Loot',

  'view.library.items.item.lootNoLootText': 'This item is not dropped by any creature.'
}
