export default {
  'view.account.changeEmail.title': 'Change Email',

  'view.account.changeEmail.newEmailLabel': 'Email (New)',
  'view.account.changeEmail.confirmPasswordLabel': 'Password',

  'view.account.changeEmail.changeButton': 'Change Email',

  'view.account.changeEmail.changeToast': 'Your email has been changed successfully.'
}
