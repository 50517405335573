export default {
  'view.account.revokeSessions.title': 'Wyloguj się z innych urządzeń',

  'view.account.revokeSessions.descriptionText': 'Spowoduje to wylogowanie z każdego urządzenia, na którym jesteś aktualnie zalogowany, z wyjątkiem bieżącego.',
  'view.account.revokeSessions.passwordLabel': 'Hasło',

  'view.account.revokeSessions.revokeSessionsButton': 'Wyloguj się z innych urządzeń',

  'view.account.revokeSessions.revokeSessionsToast': 'Sesje z innych urządzeń zostały unieważnione.'
}
