<template>
  <slot v-if="count === 0 || data?.length === 0" name="empty"/>
  <slot v-else-if="data" name="resolved"/>
  <slot v-else name="unresolved"/>
</template>

<script>
export default {
  name: 'v-state',
  props: [
    'data',
    'count'
  ]
}
</script>
