export default {
  'view.threads.newPost.title': 'Nowy post',

  'view.threads.newPost.contentLabel': 'Treść ({min}-{max} znaków)',
  'view.threads.newPost.previewLabel': 'Podgląd',

  'view.threads.newPost.previewButton': 'Podgląd',
  'view.threads.newPost.addPostButton': 'Nowy post',

  'view.threads.newPost.addPostToast': 'Pomyślnie dodałeś nowy post.'
}
