import cookies from 'js-cookie'
import objectPath from 'object-path'

cookies.withAttributes({
  sameSite: 'strict',
  secure: true
})

const getItem = path => {
  const item = cookies.get(`state.${path}`)

  if (item) {
    const object = JSON.parse(item)

    return object.value ?? null
  }

  return undefined
}

const setItem = (option, state) => {
  const object = { value: objectPath.get(state, option.path) }

  const options = {}

  if (typeof option.expires === 'function') {
    options.expires = (option.expires)()
  } else if (option.expires) {
    options.expires = option.expires
  } else {
    options.expires = cookies.attributes.expires
  }

  cookies.set(`state.${option.path}`, JSON.stringify(object), options)
}

export default options => store => {
  if (typeof window === 'undefined') {
    return
  }

  options.forEach(option => {
    const item = getItem(option.path)

    if (item) {
      objectPath.set(store.state, option.path, item)
    }
  })

  store.subscribe((_, state) => {
    options.forEach(option => {
      setItem(option, state)
    })
  })
}
