<template>
  <section class="section">
    <div class="container">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><a class="has-pointer-events-none">&hellip;</a></li>
          <template v-for="{ name, title } in breadcrumbs" :key="title">
            <li :class="{ 'is-active': !name }">
              <router-link :to="{ name }" :aria-current="!name ? 'page' : undefined">{{ title ? (title.startsWith('$t:') ? $t(title.replace('$t:', '')) : title) : name }}</router-link>
            </li>
          </template>
        </ul>
      </nav>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

import { getTitle } from '@/lib/route'

export default defineComponent({
  name: 'v-breadcrumbs',
  computed: {
    breadcrumbs () {
      const breadcrumbs = []

      const parentRoute = this.getParentRoute()
      const route = this.getRoute()

      if (parentRoute) {
        const name = parentRoute.name.toString()

        const title = getTitle(name) ?? parentRoute.meta?.title ?? '...'

        breadcrumbs.push({ name, title })
      }

      return [
        ...breadcrumbs,
        route
      ]
    }
  },
  methods: {
    getRoute () {
      return {
        title: getTitle() ?? '...'
      }
    },
    getParentRoute () {
      const matchedNames = [
        ...this.$route.matched.slice(0, -1).map(({ name }) => `${name.toString()}.index`),
        this.$route.name
      ]

      const currentRouteIndex = matchedNames.findIndex((matchedName) => {
        return matchedName === this.$route.name.toString()
      })

      const parentRouteName = matchedNames[currentRouteIndex - 1]

      if (!parentRouteName || !this.$router.hasRoute(parentRouteName)) {
        return undefined
      }

      return this.$router.resolve({ name: parentRouteName })
    }
  }
})
</script>
