<template>
  <div class="has-text-centered is-unselectable mb-3">&bull;&bull;&bull;</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'v-divider'
})
</script>
