<template>
  <nav class="navbar is-spaced is-transparent has-background-transparent" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link :to="{ path: '/' }" class="navbar-item">
        <img src="@/assets/images/Brand.png" alt="Rookie.com.pl">
      </router-link>
      <a ref="burger" @click="toggleNavbar" role="button" class="navbar-burger" aria-label="menu" aria-expanded="false">
        <template v-for="i in 3" :key="i">
          <span aria-hidden="true"></span>
        </template>
      </a>
    </div>
    <div ref="menu" class="navbar-menu">
      <div class="navbar-end">
        <template v-for="{ icon, translationKey, name } in routes" :key="name">
          <router-link :to="{ name }" class="navbar-item">
            <span class="icon-text">
              <span class="icon is-hidden-touch">
                <fa-icon :icon="icon"/>
              </span>
              <span>{{ $t(translationKey) }}</span>
            </span>
          </router-link>
        </template>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { getTitle } from '@/lib/route'

export default defineComponent({
  name: 'v-navbar',
  watch: {
    $route (): void {
      this.closeNavbar()
    }
  },
  computed: {
    title (): string {
      return getTitle()
    },
    routes (): { icon: [string, string], translationKey: string, name: string }[] {
      return [
        { icon: ['fas', 'rss'], translationKey: 'view.blog.index.title', name: 'blogs.index' },
        { icon: ['fas', 'book'], translationKey: 'view.library.index.title', name: 'library.index' },
        { icon: ['fas', 'comments'], translationKey: 'view.forums.index.title', name: 'forums.index' },
        { icon: ['fas', 'users'], translationKey: 'view.users.index.title', name: 'users.index' },
        { icon: ['fas', 'shield'], translationKey: 'view.rookstat.index.title', name: 'rookstat.index' },
        { icon: ['fas', 'calculator'], translationKey: 'view.calculators.index.title', name: 'calculators.index' },
        { icon: ['fas', 'cloud-upload-alt'], translationKey: 'view.images.index.title', name: 'images.index' },
        { icon: ['fas', 'shield-heart'], translationKey: 'view.fansiteItem.title', name: 'fansiteItem' },
        { icon: ['fas', 'key'], translationKey: 'view.account.index.title', name: 'account.index' }
      ]
    }
  },
  methods: {
    toggleNavbar (): void {
      const $html = document.querySelector('html') as HTMLElement
      const $burger = this.$refs.burger as HTMLElement
      const $menu = this.$refs.menu as HTMLElement

      $html.classList.toggle('is-clipped')
      $burger.toggleAttribute('aria-expanded')
      $burger.classList.toggle('is-active')
      $menu.classList.toggle('is-active')
    },
    closeNavbar (): void {
      const $html = document.querySelector('html') as HTMLElement
      const $burger = this.$refs.burger as HTMLElement
      const $menu = this.$refs.menu as HTMLElement

      $html.classList.remove('is-clipped')
      $burger.removeAttribute('aria-expanded')
      $burger.classList.remove('is-active')
      $menu.classList.remove('is-active')
    }
  }
})
</script>
