export default {
  'view.rookstat.players.index.title': 'Players',

  'view.rookstat.players.index.trendingRookstayerLabel': 'Trending Rookstayer',

  'view.rookstat.players.index.trendingRookstayerLevelText': 'Level',
  'view.rookstat.players.index.trendingRookstayerGlobalRankText': 'Global Rank',
  'view.rookstat.players.index.trendingRookstayerWorldRankText': 'World Rank',
  'view.rookstat.players.index.trendingRookstayerWorldText': 'World',

  'view.rookstat.players.index.nameLabel': 'Name',

  'view.rookstat.players.index.statusLabel': 'Status',
  'view.rookstat.players.index.statusAllOption': 'All',

  'view.rookstat.players.index.worldLabel': 'World',
  'view.rookstat.players.index.worldAllOption': 'All',

  'view.rookstat.players.index.sortLabel': 'Sort',

  'view.rookstat.players.index.specialCodesText': 'You can configure your character in the ranking using special codes.',

  'view.rookstat.players.index.nameText': 'Name',
  'view.rookstat.players.index.worldText': 'World',
  'view.rookstat.players.index.accountText': 'Account',
  'view.rookstat.players.index.scoreText': 'Score',

  'view.rookstat.players.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
