export default {
  component: {
    score: {
      scoreZeroHelp: 'The score is very low.',
      scoreTooLowHelp: 'The score is too low.',
      scoreOkHelp: 'The score is high enough.',
      scoreMaxHelp: 'Awesome! The score is very high.'
    }
  }
}
