export default {
  state: {
    routeTitles: [],
    routeDescriptions: []
  },
  getters: {
    getRouteTitle: ({ routeTitles }) => path => {
      const routeTitle = routeTitles.find(routeTitle => routeTitle.path === path)

      if (!routeTitle) {
        return undefined
      }

      return routeTitle.routeTitle
    },
    getRouteDescription: ({ routeDescriptions }) => path => {
      const routeDescription = routeDescriptions.find(routeDescription => routeDescription.path === path)

      if (!routeDescription) {
        return undefined
      }

      return routeDescription.routeDescription
    }
  },
  mutations: {
    SET_ROUTE_TITLE: (state, routeTitle) => {
      state.routeTitles = state.routeTitles.filter(aRouteTitle => aRouteTitle.path !== routeTitle.path)
      state.routeTitles.push(routeTitle)
    },
    SET_ROUTE_DESCRIPTION: (state, routeDescription) => {
      state.routeDescriptions = state.routeDescriptions.filter(aRouteDescription => aRouteDescription.path !== routeDescription.path)
      state.routeDescriptions.push(routeDescription)
    }
  }
}
