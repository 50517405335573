export default {
  'view.images.index.title': 'Images',

  'view.images.index.usernameLabel': 'Username',

  'view.images.index.tagLabel': 'Tag',
  'view.images.index.tagAllOption': 'All',
  'view.images.index.tagAdvancementOption': 'Advancement',
  'view.images.index.tagBossOption': 'Boss',
  'view.images.index.tagCollectionOption': 'Collection',
  'view.images.index.tagEventOption': 'Event',
  'view.images.index.tagFansiteEventOption': 'Fansite Event',
  'view.images.index.tagFriendsOption': 'Friends',
  'view.images.index.tagFunnyOption': 'Funny',
  'view.images.index.tagLootOption': 'Loot',
  'view.images.index.tagOldTibiaOption': 'Old Tibia',
  'view.images.index.tagTrapandoOption': 'Trapando',
  'view.images.index.tagOtherOption': 'Other',
  'view.images.index.tagBlogRookieTeamOption': 'Blog (Rookie Team)',
  'view.images.index.tagOtherRookieTeamOption': 'Other (Rookie Team)',

  'view.images.index.sortLabel': 'Sort',
  'view.images.index.sortCreatedAtAscOption': 'Created At ― Ascending',
  'view.images.index.sortCreatedAtDescOption': 'Created At ― Descending',

  'view.images.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
